import i18next from 'i18next';

export function translationExists(translation) {
  return i18next.exists(translation);
}

i18next.init({
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'en',
  resources: {
    en: {
      translation: {
        general: {
          today: 'Today',
          loading: 'Loading...'
        },
        header: {
          users: 'Users',
          quiz: 'Test',
          questions: 'Questions',
          statistics: 'Statistics',
          skills: 'Skills & Sub-Skills',
          settings: 'Settings',
          domain: 'Domain Info'
        },
        skills: {
          COLLABORATION: 'Collaboration',
          CRITICAL_THINKING: 'Critical Thinking',
          PROBLEM_SOLVING: 'Problem Solving',
          LEADERSHIP: 'Leadership',
          COMMUNICATION: 'Communication',
          INTERCULTURALISM: 'Interculturalism',
          LEARNING: 'Learning',
          systemSkills: "System's Skills",
          createNewSkill: 'New Skill',
          noSkillFound: 'No Skill found in the system',
          systemSubSkill: 'Sub-Skill',
          systemSubSkills: 'Sub-Skills',
          noSubSkillForSkill: 'No Sub-Skill defined for the selected skill',
          systemSubSkillsDescription: 'Define one or more Sub-Skills associated with the current Skill'
        },
        skillsReport: {
          reportIntroductionTitle: 'Introduction',
          reportIntroduction:
            "Edulai Skills Checker© is a software from the Edulai package designed to measure users' skills through the administration of specific professional cases.\n\nThe purpose of Edulai Skills Checker© is to evaluate the level of transversal skills and sub-skills on a scale from 1 (basic) to 5 (expert), which are key tools for the future job market.\n\nThe Skills Checker© presents professional cases that the user must solve by choosing aresponse from various possible options, reflecting on behaviors that may be closer to their personal experience or that the user chooses to implement to solve new problems.\n\n",
          reportSkillsListIntro: 'The skills evaluated by this test are as follows:',
          reportSkillsValueListIntro:
            'The scale used for the evaluation includes the following levels/stars for each skill:',
          reportIntroductionEnd:
            "Once the evaluation is completed, the system provides access to a guide manual for reading one's results and the definitions of the skills.",
          reportIntroductionSkillsValue:
            '• 1 star – Basic\n• 2 stars – Beginner\n• 3 stars – Intermediate\n• 4 stars – Advanced\n• 5 stars – Expert',
          skillsResultsTitle: 'SKILLS CHECKER RESULTS FOR EACH SKILL & SUB-SKILL',
          skillsChartResultsTitle: 'SKILLS CHECKER RESULTS',
          skillsResultsValueTitle: 'REFERENCE VALUE (1-5)',
          strenghts: 'Strenghts',
          weaknesses: 'Weaknesses',
          areasDevelopments: 'Areas of developments',
          noWeaknesses: 'Currently the report does not show skills to improve to discuss.',
          noStrenghts: 'Currently the report does not show high level skills to discuss.',
          noAreasDevelopments: 'Currently the report does not show areas of developments.',
          subSkillStrenghtMessage:
            'The report has several strenghts and high level sub-skills especially for <strong>{{ subSkills }}</strong>. As for consequence you show strenghts in these area.',
          subSkillWeaknessLowMessage:
            'The report shows some areas of improvement especially for sub-skills such as <strong>{{ subSkills }}</strong>. ',
          subSkillWeaknessMediumMessage:
            'Skills as <strong>{{ subSkills }}</strong> could be improved as well, although they start from a good level.',

          skillStrenghtMessage:
            'The report shows different strenghts and high level skills especially for <strong>{{ skills }}</strong>. You show strenghts in the above areas.',
          skillWeaknessLowMessage:
            'The report shows different areas of improvement and low level skills especially for <strong>{{ skills }}</strong>. ',
          skillWeaknessMediumMessage:
            'Also skills such as <strong>{{ skills }}</strong> could be improved although they are at a good initial level.',

          skillReport1:
            'The report shows the presence of skills such as <strong>{{ skills }}</strong> that you have self-assessed lower compared to the evaluation carried out by the test. These, in particular, are areas that require a certain degree of depth, especially regarding the perception you have of yourself and your areas for improvement. For the skills self- assessed with scores close to or slightly higher than the test level, you have demonstrated good awareness and knowledge of yourself.',
          skillReport2:
            'The report shows the presence of skills such as  <strong>{{ skills }}</strong> that you have self-assessed higher compared to the evaluation carried out by the test. These, in particular, are areas that require a certain degree of depth, especially regarding the perception you have of yourself and your strengths. For the skills self-assessed with scores close to or slightly lower than the test level, you have demonstrated good awareness and self-knowledge.',
          skillReport3:
            'The report shows the presence of skills such as  <strong>{{ skills }}</strong> that you have self-assessed in line with the evaluation carried out by the test. These, in particular, are areas that demonstrate a good degree of awareness of your strengths and weaknesses. The skills you have self-assessed with scores higher/lower than the test level require a certain degree of depth, especially regarding the perception you have of yourself, your limits, and your potential.',
          skillReport4:
            'The report shows the presence of skills such as  <strong>{{ skills1 }}</strong> that you have self-assessed higher compared to the evaluation carried out by the test. These, in particular, are areas that require a certain degree of depth, especially regarding the perception you have of yourself and your strengths. You also have a good portion of skills such as <strong>{{ skills2 }}</strong> that you have self-assessed lower compared to the evaluation carried out by the test. These too require a certain degree of depth, especially regarding the perception you have of yourself and your areas for improvement.',
          COLLABORATION:
            'Improving our own personal perception in relation to team work is key, considering it a precious resource to draw on, for the realization of a task even if it sometimes requires a greater investment of time. Often the varied contributions that all the members of a team can give enrich the group, other times it can be a source of conflict. At the same, time, training or coaching sessions to improve this skill could be useful.',
          PROBLEM_SOLVING:
            'Improve and vary your approach to problem solving. Often there is no single way to solve that type of problem. Creativity and critical thinking help to develop our ability to analyze the situation and then to find the most suitable solution or solutions. Finding even an original solution also requires time and a certain degree of reflection. There are different methods and approaches to problem solving: training or coaching sessions to improve this skill could be useful.',
          LEADERSHIP:
            "Improving one's approach to leadership often requires the ability to accept constructive feedback from others, as well as the ability to offer it. Leadership is a continuous learning process where the leader often creates a climate of trust and appreciation of the talents of others. To develop one's work and responsibilities the leader is based on the spontaneous collaboration of his colleagues, and a good leader is able to enhance this collaboration without any kind of forcing. Training or coaching sessions to improve this skill could be useful, as well as management of work groups.",
          COMMUNICATION:
            "Improving one's approach to communication often requires the ability to clearly clarifyits context and purpose. Often the content of the communication derives from one's own knowledge and beliefs, other times it can be supported by external sources or resources (readings, research, reports, etc.) In communication it is important to understand, listen and then communicate also according with who is the person in front of you (man, woman, contact person, employee). Training or coaching sessions to improve this skill could be useful, as could daily practice and continuous exchange with others.",
          INTERCULTURALITY:
            "Improving one's approach to different cultures, often requires the ability to open up to others, to the new, and to know how to listen to. Curiosity towards what is unknown helps, as does empathy, as well as the ability to put oneself in other people's shoes to understand better. The knowledge and understanding of one's own BIAS and limits (having grown up in a closed environment, not having been exposed to different cultures) is equally important to develop this skill. Training or coaching sessions to improve this skill could be useful, as well as exchanging and coming into contact with people from different cultures.",
          LEARNING:
            'Improving your learning means always being open to new things. The learning process is continuous, it has no beginning or end and being continuously stimulated by knowledge helps you never stop learning. This also involves being able to monitor your own path and modify it if necessary according to your needs; just as daily learning asks us to understand what is most important to us and where we want to focus our attention during this never-ending process. Training sessions to improve this skill could be useful, as well as reflecting on what is most important to us among the stimuli we receive every day.',
          CRITICAL_THINKING:
            'Improving your critical thinking is not easy and often requires good thinking and analytical skills, that is, analyzing a situation or a problem. This also means thinking in an alternative way, not being afraid to bring new ideas into account, sometimes even in contrast with those of others. Similarly, this exercise is key to when identifying implications and consequences. Training sessions to improve this skill and train it could be useful, as well as the practice of alternative thinking that analyzes a situation, evaluates different approaches, drawing conclusions and subsequent consequences.',
          RESULT_ORIENTATION:
            "This skill requires being able to prioritize one's goals as well as to identify and apply suitable strategies to achieve them, persevering. It is a key skill that can be developed by practicing and putting daily goals in order of importance and reflecting on identifying suitable strategies to achieve them. It is also important not to be discouraged by failure but to consider it as a learning moment for the next attempt. Coaching and training can certainly help in developing this skill.",
          ASSERTIVENESS:
            'Assertiveness is the skill that allows us to practice an attitude or communication that is not too aggressive or too passive with others. Training to be clear and open without hurting anyone can be a good strategy, as well as trying to make decisions with others without the necessary aggression or passivity. Often the best solution is the practice of assertive communication in relationships, training in interacting in the right way and receiving feedback from individuals. At the same time, coaching sessions could be useful to improve it.',
          RESILIENCE:
            'Resilience is a key skill in the job market and is more in demand than ever. To develop it, it is important to learn not to let obstacles, big or small, get you down, just as it is important to exercise perseverance and adaptation, flexibility in problem solving. It is also important to try, to overcome obstacles even by trial and error, to resolve them, perhaps not immediately, but soon. Arming yourself with patience and trust is key, as is optimism and energy. Training or coaching sessions could be useful to improve it, as well as the daily practice of overcoming problems or persistent obstacles.',
          DIGITAL_COLLABORATION:
            'Improve your personal perception of remote teamwork, considering it a valuable resource to draw on to complete a task even if it sometimes requires a greater investment of time, especially when working remotely. Often, the varied contribution that all members of a group can provide can be enriching, other times it can be a source of conflict. At the same time, training or coaching sessions to improve this skill could be useful.',
          DIGITAL_PROBLEM_SOLVING:
            'Improve and diversify your approach to problem solving. Often there is no single way to solve that type of problem. Creativity and critical thinking help develop our ability to analyse the situation and then find the most suitable solution or solutions, especially when dealing with technology. Finding even an original solution and implementing it also requires time and a certain degree of reflection, as does being able to have a peaceful relationship with technology. There are different methods and approaches to problem solving: training or coaching sessions to improve this skill could be useful.',
          DIGITAL_LEADERSHIP:
            'Improving your approach to digital leadership often requires a certain organizational capacity and autonomy in your work, the ability to define the tasks of your group, identify their priorities and measure them even when you are from remote location. Leadership is a continuous learning process where the leader often creates a climate of trust and valorisation of the others’ talents, even when the relationship with team members is mediated by technology. Training or coaching sessions to improve this skill ,could be useful, as well as the management of work groups.',
          DIGITAL_COMMUNICATION:
            'Improving your approach to digital communication often requires the ability to clearly clarify its context and purpose, even online. It is also necessary to know the different types of communication technologies, in order to choose the appropriate ones that are most effective for the communication you want to achieve. In communication, it is important to understand, listen and then communicate also according with the individual you have in front of you (man, woman, contact person, employee), as well as knowing how to use technology to present content to the public (Different types of presentations). Training or coaching sessions to improve this skill could be useful, as well as daily practice and continuous exchange with others, through technology.',
          AUTONOMY:
            'Improving your autonomy means being able to define tasks, deadlines, objectives of a specific activity autonomously, independently, and then carry them out. Autonomy requires important organizational skills especially when you work remotely and there is no regular contact with others face to face. It requires knowing how to manage your time and resources in the best of ways, as well as your team. It is often a skill that needs to be trained by trying to complete small tasks alone, and then gradually managing more complex ones, respecting set deadlines. Training or coaching sessions to improve this skill could be useful, as well as daily practice in managing work tasks even remotely.',
          PROACTIVITY:
            'Improving your proactivity means being able to anticipate and predict situations, problems, events before they happen. Foresight, the ability to observe and listen to others and what are around us, help guide us and predict events and then act shortly before or while they happen. When working remotely, this skill requires us to carefully observe what happens digitally, in email communication and in virtual meetings with others. Developing these skills as well as learning from past experience, is certainly key to strengthening them. Training or coaching sessions could be useful to improve it, as well as the daily practice of observation and listening even online.',
          EMPATY:
            "Empathy, especially when working remotely, is a key skill today. Being able to imagine yourself in someone else's shoes or being sensitive to others' emotions even if they are not physically with you in the same place, is important for the professions of the future. It is key to train yourself to put the person, the relationship at the center and try to &quot;understand&quot; and &quot;listen&quot; even if others are not physically close to us. Training or coaching sessions could be useful to improve it, as well as trying to focus on the other and not on ourselves or on technology, when working remotely, practicing the art of listening and observation.",
          DIGITAL_TECHNOLOGY:
            'Using digital technologies can often be an obstacle, especially when working remotely or at a distance. Daily practice in communicating via email, video conference, messaging, etc. can help not only to overcome specific fears or initial obstacles but also to improve the use, the way, and the purposes for which technologies are used. Practice can help us understand that each technological tool is suitable for a specific purpose and that technologies can be used to efficiently achieve our goals. This can help us to be more confident with them, better managing stressful situations. Training sessions but also regular practice in their use are certainly helpful.'
        },
        login: {
          noService: 'Service temporary not available. Please reload the page to retry',
          welcome: 'Welcome back',
          passwordRecovery: 'Forgot your password?',
          passwordRecoveryBody: 'Enter your email below. We will send you and email with a link to reset your password',
          loginError: 'Login error. Please check your credentials and try again',
          welcomeFirstTime: 'Set your password to access',
          confirmRegistrationError: 'An error occurred while creating your account. Please try again',
          creatingYourAccount: 'We are creating your new account!',
          createAccountSuccess: 'Account created successfully! Check your email to set your password and login',
          resetPasswordSuccess: 'An email with password recovery instructions has been send!',
          resetPasswordError: 'An error occurred on the password recovery. Please try again',
          passwordSetSuccess: 'Password set successfully!',
          passwordSetError: 'An error occurred while creating the new password. Please try again',
          insertEmail: 'Your email',
          insertPassword: 'Your password',
          registerNow: 'No account yet? Register now!',
          registerUserBody: 'Insert your data to create your Edulai account!',
          registerUserEmail: 'We will send you and email with the instructions to set your password',
          createNewAccount: 'Create a new account',
          acceptPrivacy: "I've read and accept the Privacy Policy",
          passwordRecoveryForUser: 'Send password recovery email'
        },
        modals: {
          cancel: 'Cancel',
          continue: 'Continue',
          confirm: 'Confirm',
          ok: 'Ok',
          compliment: 'Congratulations!',
          publish: 'Publish',
          close: 'Close',
          delete: 'Delete',
          save: 'Save',
          reloadPage: 'Reload Page',
          closeWithoutSave: 'Close without saving'
        },
        languages: {
          language: 'Language',
          it: 'Italian',
          en: 'English',
          ITALIAN: 'Italian',
          ENGLISH: 'English',
          selectLanguage: 'Select a language'
        },
        user: {
          profile: 'Profile',
          ageRange: 'Age',
          gender: 'Gender',
          nation: 'Nation',
          employment: 'Employment',
          professionName: 'Profession',
          experienceAgeRange: 'Years of experience',
          educational: 'Educational level',
          portfolioDescription: 'Portfolio',
          employmentsTypes: {
            STUDENT: 'Student',
            UNEMPLOYED: 'Unemployed',
            EMPLOYED: 'Employed'
          },
          ageRangeTypes: {
            RANGE18_22: '18-22 years',
            RANGE23_25: '23-25 years',
            RANGE26_30: '26-30 years',
            RANGE31_40: '31-40 years',
            RANGE41_50: '41-50 years',
            RANGE51_99: '50+'
          },
          experienceAgeRangeTypes: {
            RANGE0_1: 'Less than one year',
            RANGE1_2: '1-2 years',
            RANGE2_5: '2-5 years',
            RANGE5_10: '5-10 years',
            RANGE10_20: '10-20 years',
            RANGE20_99: 'More than 20 years'
          },
          genderTypes: {
            MALE: 'Male',
            FEMALE: 'Female',
            UNSPECIFIED: 'Unspecified'
          },
          educationalTypes: {
            SECONDARY_SCHOOL: 'High school',
            GRADUATION: 'University degree',
            POSTGRADUATE_CERTIFICATE: 'Post-university certification'
          },
          onboardingUpdatedAt: 'Profile updated at {{date}}'
        },
        forms: {
          copySuccess: 'Link copied on clipboard',
          inactiveUserActivationLink: 'Activation Link',
          inactiveUserExplanation: 'User has not yet activate his account',
          inactiveUserCopyLink:
            'If the user has difficulty activating his account, you can send him this activation link',
          inactiveUserCopyWarning:
            'Warning: use this link only in cases of extreme necessity. Check that the user has entered a valid email and that he is sending the link to the correct account. Once in possession of the activation link, anyone can also activate this account incorrectly',
          selectSkill: 'Select a skill',
          today: 'Today',
          thisMonth: 'This month',
          thisYear: 'This year',
          last6Months: 'Last 6 months',
          thisWeek: 'This week',
          edulaiTagNotModify: 'The Edulai group cannot be modified',
          tagName: 'Group name',
          selectSkillsToCreateAnswers: 'Select at least two Skills and Sub-Skills to start create new answers',
          forceUserOnboardingDescription: 'Force user to take onboarding again',
          forceUserOnboarding: 'Force onboarding',
          newSubSkill: 'New Sub-Skill',
          selfAssessmentDescription:
            'For each skill indicate your mastery level with a value from 1 to 5, where 1 indicates the basic level and 5 the Expert level',
          saveDraft: 'Save test draft',
          backtoLogin: 'Back to Login',
          noOptions: 'No options',
          loadingOptions: 'Loading options',
          companyRolePlaceholder: 'Search role...',
          required: 'Required',
          save: 'Save',
          search: 'Search',
          reset: 'Reset',
          name: 'Name',
          edit: 'Edit',
          send: 'Send',
          createNew: 'Create new',
          saveChanges: 'Save changes',
          firstName: 'Name',
          lastName: 'Last name',
          address: 'Address',
          next: 'Next',
          back: 'Back',
          skip: 'Skip',
          finish: 'Finish',
          optional: 'Optional',
          confirmPassword: 'Confirm password',
          newPassword: 'Create your password',
          passwordValidation: 'Minimum 8 characters a letter and no special characters',
          passwordMustCoincide: 'Password must coincide',
          passwordSpecialCharacters: 'NO special characters',
          warning: 'Warning',
          confirm: 'Confirm',
          close: 'Close',
          bio: 'Bio',
          emailNotValid: 'Email not valid',
          logoutConfirmation: 'Are you sure you want to logout?',
          iAcceptThe: "I've read and accept the",
          privacyPolicy: 'Privacy Policy',
          confirmPasswordLabel: 'Confirm your password',
          confirmRegistration: 'Create your account',
          confirmPasswordTitle: 'Confirm password',
          userRole: 'User role',
          deleteUser: 'Delete user',
          disableUserExplanation: 'Disable the user to revoke all the priviliges in the institution',
          userStatus: 'User status',
          enabledUserExplanation: 'The user has been disabled',
          onboardingTitle: 'Welcome to Edulai!',
          onboardingDescription:
            "We welcome you to <strong>Edulai Skills Checker</strong>. Before we start, we kindly ask you to take a couple of minutes to let us get to know you better. How do you evaluate your skills? Take your time to answer and be honest with yourself. Let's get started now.",
          whatIsYourAge: 'How old are you?',
          startEdulai: 'Start using Edulai!',
          whatIsYourGender: "What's your gender?",
          male: 'Male',
          female: 'Female',
          other: 'Other',
          insertNation: 'Insert your nation',
          whatIsYourNation: "What's your nation?",
          whatIsYourOccupancy: 'You are currently',
          student: 'Student',
          unemployed: 'Unemployed',
          professionist: 'Employed professionist',
          whatIsYourJob: 'If you are employed, what is the name of your main professional role?',
          insertJobTitle: 'Enter the name of your role',
          yearsOfExperince: 'How many years of professional experience you have in that role?',
          insertYearsOfExperince: 'Insert your years of experience',
          years: 'Years',
          lessThanOneYear: 'Less then 1 year',
          moreThanTwentiYear: 'More than 20 years',
          whatIsYourScholarLevel: 'What is your school level?',
          highSchool: 'High school',
          universityDegree: 'University degree',
          postUniversityDegree: 'Post-university certification',
          stageOrInsterests:
            'Have you ever done internships of any kind, studies or work abroad, or do you have personal interests that have helped you develop skills for the job market? If yes, select the skills you have gained and explain below which experiences have been acquired. If applicable, you can discuss these skills directly with employers',
          stageOrInsterestsSupport:
            'Cross this box if you have material produced to support the skills you have listed above, as part of your professional portfolio to show to employers and indicate what material it is (e.g. video recordings of your presentations, written works or projects you made, etc)',
          stageOrInsterestsBadges:
            'Have you ever obtained digital badges for one or more of the above skills? If so, does it indicate for which competences you have gained them? Please also provide the link to badges',
          skillName: 'Skill name',
          newSkillWarning:
            'The new skill will only be available for creating or editing new questions. The changes will not involve Test already created or in progress',
          skillCreatedSuccess: 'Skill created successfully',
          skillCreatedError: 'An error occurred while creating the skill. Please try again',
          skillUpdatedSuccess: 'Skill updated successfully',
          skillUpdatedError: 'An error occurred while updating the skill. Please try again',
          skillDeleteWarning:
            'By deleting this skill you will make it unavailable for new tests. Previous tests including this skill will not be affected by this change and the skill will still be visibile on statistics',
          skillDeleteError: 'An error occurred while deleting this skill',
          skillDeleteSuccess: 'Skill deleted successfully',
          subSkills: 'Sub-Skills',
          subSkillDeleteWarning:
            'By deleting this Sub-Skill you will make it unavailable for new tests. Previous tests including this Sub-Skill will not be affected by this change and the Sub-Skill will still be visibile on statistics',
          subSkillCreatedSuccess: 'Sub-Skill created successfully',
          subSkillCreatedError: 'An error occurred while creating the Sub-Skill. Please try again',
          subSkillUpdatedSuccess: 'Skill updated successfully',
          subSkillUpdatedError: 'An error occurred while updating the Sub-Skill. Please try again',
          subSkillDeleteError: 'An error occurred while deleting this Sub-Skill',
          subSkillDeleteSuccess: 'Sub-Skill deleted successfully',
          deleteSubSkill: 'Delete Sub-Skill',
          deleteSkill: 'Delete Skill',
          expirationDate: 'Expiration date',
          creationDate: 'Creation date',
          credits: 'Remaining credits',
          subscriptionType: 'License type',
          addCredits: 'Add credits',
          setCredits: 'Set credits',
          totalCredits: 'Total credits',
          creditsLabel: 'Credits',
          addCreditsDescription: 'Modify the credits for the institution',
          addCreditSuccess: 'Credits modified successfully',
          addCreditError: 'An error occurred while modifying the credits. Please try again',
          modifyDomainType: 'Modify license type',
          modifyDomainTypeDescription:
            'You can change the type of domain license to Flat License (unlimited credits) and PPU License (limited credits).',
          modifyDomainTypeWarning: 'Are you sure you want to change the license?',
          modifyDomainTypeSuccess: 'License successfully modified',
          modifyDomainTypeError: 'An error occurred while changing the license. Please try again',
          flatSubscriptionDescription: 'Flat (unlimited credits)',
          ppuSubscriptionDescription: 'PPU (pay per credits)',
          flatCreditsDescription:
            'In FLAT-type domains the remaining credits will not be deducted on new tests submissions',
          domainStatus: 'Domain Status',
          disabled: 'Disabled',
          enabled: 'Active',
          disableDomain: 'Disable Domain',
          enableDomain: 'Activate Domain',
          enableUserSuccess: 'User enabled successfully',
          enableUser: 'Enable user',
          disableDomainRequest:
            'Are you sure you want to disabled the domain? It will not be possible to create new users, submit new answers and create new tests',
          modifyDomainStatusSuccess: 'Domain status modified successfully',
          modifyDomainStatusError: 'An error occurred while modifying the domain status',
          title: 'Title',
          text: 'Text',
          evaluatedSkills: 'Evaluated skills',
          startDate: 'Start date',
          startTime: 'Start time',
          startDateTime: 'Start date & time',
          endDate: 'End date',
          endTime: 'End time',
          endDateTime: 'End date & time',
          min1hr: '1 hour minimum',
          startBeforeEnd: 'Start date after end date',
          newEndBeforeOldEnd: 'New end date is before original one',
          quizInPast: "Can't create test in the past",
          delete: 'Delete',
          totals: 'Totals',
          total: 'Total',
          creditsAfterOperations: 'Credits after the operation: {{credits}}',
          selectOperation: 'Select operation',
          modifyCredits: 'Modify Credits',
          userTagPlaceholder: 'Select a group for the user',
          userTag: 'User group',
          userTagDescription: 'Use groups to allow you to easily select multiple users',
          createNewOption: 'Create new',
          createNewTagDescription: 'Create a new group for users',
          createNewTag: 'Create new group',
          modifyTagDescription: 'Modify the group of users',
          deleteTagConfirm: 'Are you sure you want to delete this group? Operation cannot be undone',
          dateInterval: 'Date interval',
          applyFilters: 'Apply filters',
          resetFilters: 'Reset filters',
          stageOrInsterestsDescription: 'Skills and experience gained on a stage',
          badgeLinksDescription: 'Digital badges links',
          edulaiTagDefaultDescription: "If you don't select a group, the user will belong to the Edulai default group",
          editSkillName: 'Edit skill name',
          cantDeleteReferencedSkill: "Can't delete skill. It's referenced by one or more questions.",
          deleteQuestionWarning:
            'By deleting this question you will make it unavailable for new tests. Previous tests including a previous version of this question will not be affected by this change',
          deleteQuestionSuccess: 'Question deleted',
          deleteQuestionError: 'An error occurred deleting the question',
          deleteQuestionInUseError: 'Error: the current version of this question is in use in a Test',
          maxCharacters: 'Max {{number}} characters'
        },
        domainTypes: {
          FLAT: 'Flat subscription',
          PPU: 'Pay per use subscription'
        },
        users: {
          inactive: 'Inactive',
          inactiveUser: 'Inactive users',
          tagUsers: 'Users',
          usersTags: 'Users groups',
          deleteTagSuccess: 'Group deleted',
          deleteTagError: 'Error while deleting the group',
          createUserTagSuccess: 'Group created',
          createUserTagError: 'Error while creating the group',
          editUserTagSuccess: 'Group modified',
          editUserTagError: 'Error while editing the group',
          userData: 'User Data',
          userProfile: 'User profile',
          platformUsers: 'Platform users',
          platformUser: 'Platform user',
          createNewUser: 'New user',
          userEmail: 'User email',
          noUsersFound: 'No user found',
          disabled: 'Disabled',
          enabled: 'Active',
          disableUser: 'Disable user',
          userModifyError: 'An error occurred while updating your data',
          userModifySuccess: 'Profile updated successfully',
          modifyUserSuccess: 'User data updated successfully',
          createUserSuccess:
            'User created successfully. The new user will be INACTIVE until it will activate its account by creating its own password',
          deleteUserConfirm: 'Are you sure to delete the selected user? The operation is not reversible.',
          resendConfirmationEmail: 'Resend confirmation email',
          resendActivationEmail: 'Send activation email',
          userAlreadyExists: 'An user with the same email already exists',
          userDeleteSuccess: 'User deleted successfully',
          disableUserSuccess: 'User disabled successfull',
          disableUserError: 'An error occurred while disabling the user. Please try again',
          newSubSkill: 'New Sub-Skill',
          activeUsers: 'Active users',
          disabledUsers: 'Disabled users',
          onboardingFailed: 'An error occurred while updating user data. Please try again'
        },
        questions: {
          newQuestion: 'New question',
          editQuestion: 'Edit question',
          findInTitle: 'Find in the title...',
          noQuestionsFound: 'No questions found',
          createQuestionSuccess: 'Question created successfully',
          createQuestionError: 'An error occurred while creating the question. Please try again',
          modifyQuestionSuccess: 'Question modified successfully',
          modifyQuestionError: 'An error occurred while modifying the question. Please try again',
          answers: 'Answers',
          newAnswer: 'New answer',
          editAnswer: 'Edit answer',
          deleteOptionConfirm: 'Are you sure to delete the selected option? The operation is not reversible.',
          optionDeleteSuccess: 'Option deleted successfully',
          optionsNumberError: 'Please, insert at least 3 valid answers.',
          firstOptionCreate:
            'After creating the first answer, editing skills and subskills will reset all the answers. Are you sure you want to proceed?',
          myAnswers: 'My answers',
          version: 'Version'
        },
        roles: {
          ROOT: 'Root',
          ADMIN: 'Admin',
          SUPERVISOR: 'Supervisor',
          USER: 'Basic user',
          PRINCIPAL: 'Principal'
        },
        rolesDescriptions: {
          ROOT: 'This role is dedicated to the managers of the Edulai cloud platform',
          ADMIN: 'The Admins are responsible for the platform user management and customers registry',
          PRINCIPAL: 'The Principal is the head of the institution. He can see global statistics and metrics',
          SUPERVISOR: 'Supervisors are responsible to create and manage tests',
          USER: 'Basic users can receive and submit answers to tests'
        },
        domain: {
          customFields: 'Custom user field',
          createCustomField: 'Create custom field',
          customFieldDescription: 'This custom field will be presented as a question in the user onboarding',
          customFieldTitle: 'Custom field name',
          customFieldBody: 'Custom field question body',
          customFieldSuccess: 'Custom fields edited successfully',
          customFieldError: 'An error occurred while editing the custom fields',
          deleteCustomFieldRequest: 'Are you sure you want to delete this custom field? Operation cannot be undone',
          customFieldDeleteSuccess: 'Field deleted successfully',
          customFieldDeleteError: 'An error occurred deleting this field'
        },
        quizzes: {
          types: {
            DRAFT: 'DRAFT',
            RUNNING: 'RUNNING',
            PAUSED: 'PAUSED',
            DONE: 'DONE',
            READY: 'READY',
            ALL: 'ALL',
            EXPIRED_FOR_USER: 'EXPIRED'
          },
          typesDescriptions: {
            DRAFT: 'Drafter test can be modified until submitted',
            RUNNING: 'The test is running and users can submit their answers',
            PAUSED: 'The test is paused and users cannot submit their answers',
            DONE: 'The test is done!',
            READY: 'Test will start when its start date expire or if started explicitly',
            EXPIRED_FOR_USER: "Test is over and you haven't submit your answers"
          },
          selectSupervisor: 'Select a Supervisor',
          selectSupervisorDescription: "If you don't select a supervisor you will be responsible for this test!",
          noCreditsError: 'Domain credits expired! Please add new credits to continue',
          activeQuizzes: 'Active tests',
          disabledQuizzes: 'Disabled tests',
          newQuiz: 'New test',
          noQuizzesFound: 'No tests found',
          editQuiz: 'Edit test',
          createQuizSuccess:
            'Test successfully created. Test will remain in "Draft" state until it will be started explicitly',
          createQuizError: 'An error occurred while creating the test. Please try again',
          participants: 'Participants',
          users: 'Users',
          questions: 'Questions',
          selectedQuestionsNumber: 'Number of question selected',
          validity: 'Validity',
          completed: 'Completed',
          progress: 'Progress',
          submit: 'Submit',
          submitDraft: 'Test ready!',
          submitQuizError: 'An error occurred while submitting the test. Please try again',
          quizSubmitSuccess: 'Test submitted successfully. Quizz will remain in "Ready" state until its start date',
          pause: 'Pause',
          resume: 'Resume',
          pauseQuizError: 'An error occurred while pausing the test. Please try again',
          quizPauseSuccess: 'Test paused successfully',
          resumeQuizError: 'An error occurred while resuming the test. Please try again',
          quizResumeSuccess: 'Test resumed successfully',
          changeStartDate: 'Change start date & time',
          forceStart: 'Start now',
          newStartDateTime: 'New start date & time',
          changeStartDateError: 'An error occurred while chainging test start date & time. Please try again',
          changeStartDateSuccess: 'Start date & time changed successfully',
          confirmForceStart: 'Are you sure you want to immediately start the test?',
          forceStartError: 'An error occurred while starting the test. Please try again',
          forceStartSuccess: 'Test started successfully',
          addNewParticipants: 'Add new participants',
          newParticipants: 'New participants',
          addParticipantsSuccess: 'New participants successfully added',
          addParticipantsError: 'An error occurred while adding new participants. Please try again',
          terminate: 'Terminate',
          confirmTerminateQuiz:
            'Are you sure you want to immidiately terminate the test? The action will be irreversible.',
          terminateQuizSuccess: 'Test successfully terminated',
          terminateQuizError: 'An error occurred while terminating the test. Please try again',
          editQuizSuccess:
            'Test successfully updated. Test will remain in "Draft" state until it will be started explicitly',
          editQuizError: 'An error occurred while updating the test. Please try again',
          reactivate: 'Reactivate',
          confirmReactivateQuiz: 'Are you sure you want to reactivate the test?',
          reactivateQuizSuccess: 'Test successfully reactivated',
          reactivateQuizError: 'An error occurred while reactivating the test. Please try again',
          createdBy: 'Supervisor',
          extendQuizSuccess: 'Test successfully extended',
          extendQuizError: 'An error occurred while extending the test. Please try again',
          newEndDateTime: 'New end date & time',
          extendExpiration: 'Extend expiration',
          confirmDeleteQuiz: 'Are you sure you want to immidiately delete the test? The action will be irreversible.',
          deleteQuizSuccess: 'Test successfully deleted',
          deleteQuizError: 'An error occurred while deleting the test. Please try again',
          showQuestionsDetails: 'Show questions details',
          givenAnswers: 'Answers completed',
          totalQuestions: 'Total Questions',
          quizStatistics: 'Statistics',
          userAnswers: 'Show answers',
          sendReminder: 'Send reminder',
          sendReminderSuccess: 'Reminder sent',
          sendReminderError: 'Send reminder failed',
          hideScoresToUsers: 'Hide scores to participants',
          usersCanDownloadReport: 'Users can downlaod individual report',
          hideScores: 'Hide scores',
          downloadReport: 'Download Report',
          hideScoresDescription:
            'If you hide the scores, users will be able to review their answers to the test but not their results',
          downloadReportDescription:
            'If you enable the option to download individual reports, users will be able to download their own report. However, if the ‘Hide Scores’ option is enabled, quiz participants will not be able to download their report.',
          hideScoreAnalyticsDescription: 'The test scores are hidden by the supervisor',
          updateHideScoreSuccess: 'Hide scores successfully updated',
          updateDownloadReportSuccess: 'Download report successfully updated',
          updateHideScoreError: 'An error occurred while updating hide scores. Please try again',
          updateDownloadReportError: 'An error occurred while updating download report. Please try again'
        },
        analytics: {
          userScoresAsc: 'From worst to best',
          userScoresDesc: 'From best to worst',
          valutationRange: 'Rating Range',
          order: 'Sorting',
          changeView: 'Change view',
          private: 'Private and confidential',
          userScore: 'Score for user',
          score: 'Score',
          subskillsScores: 'Subskills scores',
          skillsScores: 'Skills scores',
          analytics: 'Analytics',
          submissionsTrend: 'Submissions trend',
          usersSubmissions: 'Participants submissions',
          noSubmissionStarted: 'No submission found for this user',
          submissionStillDraft: "The user has started a submission but hasn't finalized it",
          currentScores: 'Test scores',
          selfAssessmentScores: 'Self assessment',
          userQuizSkillsScore: 'User scores for the test',
          usersQuizSkillsScore: 'User scores',
          skillsTrendChart: 'Skills trend chart',
          leastPerformingUser: 'Least performing user',
          bestPerformingUser: 'Best performing user',
          skills: 'Skills',
          all: 'All',
          errorCreateCSV: 'An error occurred while creating the CSV',
          errorCreatePDF: 'An error occurred while creating the PDF',
          chartForUser: 'Analytics for user',
          chartForUserOverTime: 'Analytics over time for user {{user}}',
          overallStatisticsForUser: 'Overall statistics of user {{user}}',
          overallAnalytics: 'System analytics',
          filters: 'Filters',
          chartMeanOverall: "Overall means of system's skills",
          chartMeanOverTime: "Overall means of system's skills in the selected time frame",
          chartForUserMean: 'Aggregate average score of user {{user}}',
          overallAnalyticsOverTime: 'System skills over time',
          overallAnalyticsOverTimeDescription:
            'This chart shows the trend of the system skills in the selected time frame',
          currentScoresOfSystem: 'Current scores of all users',
          summaryDescription: 'This chart shows the system scores for all platform users for each skill',
          userSummaryScores: 'Aggregated score for every user',
          userSummaryScoresDescription: 'This chart shows for each user the average scores',
          detailedAnalytics: 'Detailed analytics',
          selectSkillForChart: 'Select a skill to see the users scores',
          usersSkillsScores: 'Users point for all skills',
          overallStatisticsForUserDescription: 'Select a user from Filters to see details',
          userSkillsScore: 'User skills scores',
          usersSkillScores: 'Users scores for {{skill}}',
          userSummaryBubbleScores: 'User percentage per score',
          userSummaryBubbleScoresDescription: 'This chart shows for every class of score the percentage of users',
          percentage: 'Percentage',
          everyone: 'All users',
          usersPercentage: 'Percentage of users',
          downloadCsvSuccess: 'Your report has been sent. Check your email in a few minutes'
        },
        submissions: {
          answer: 'Answer',
          submissionLoadFailed: 'An error occurred while loading the test',
          pleaseReload: 'Please try again.',
          submissionsFor: 'Submissions for {{quizName}}',
          closedAt: 'closed at',
          arePaused: 'are suspended.',
          waitForSupervisor: 'Waif for further instructions from the test supervisor',
          willStart: 'will start',
          soon: 'soon',
          areOpen: 'are open',
          startNewSubmission: 'start the test',
          noAnswerSelectedError: 'Please, select one of the options.',
          submitAnswer: 'Submit answer',
          concludeQuiz: 'Finish test',
          answerSubmitSuccess: 'Answer successfully submitted',
          answerSubmitError: 'An error occurred while submitting the answer. Please try again',
          quizSubmitted: 'Test submitted',
          submissionSubmitSuccess: 'Test successfully submitted',
          submissionSubmitError: 'An error occurred while closing the test. Please try again',
          sybmissionGuidelinesSmartWorking:
            "With the words “Remote Working” we refer to the possibility to carry out one's work wherever one is, regardless of his/her geographic location, even if it can mainly take place from home. This definition also includes the use of communication technologies such as PC, internet, email, video conferencing systems, for the realization of daily work tasks and for working with colleagues in virtual/online teams. (Smarter Working Guide, 2007)",
          submissionCompleted: 'You have answered all questions! Send your responses to complete the test',
          noSubmissionSendQuizDone: "You haven't submit your answers for this test!",
          submissionsRefusedNoCredits:
            "Your institution can't accept further submissions. Please contact your supervisor",
          title:
            "Edulai Skills Checker evaluates the level of some of your key skills, essential for tackling today's job market challenges.",
          description1:
            "We will present you with several concrete professional cases to read carefully. Each case describes a specific situation, and you will need to choose, among the provided answers/solutions, the one you think is most appropriate for that specific case. Don't overthink it. There are no right or wrong answers. They are just different approaches that help us better define your skills.",
          description2:
            'Completing the Skills Checker takes between 15 and 20 minutes. The answers must be provided in the proposed sequence. You cannot skip them or move back and forth between questions. The test must be completed all at once, but if technical issues prevent you from finishing it, you will have the opportunity to log in again and submit the missing answers. Once all responses are completed, the system will show you the results, and you will have access to both a general report and a more specific one on the development of your skills. You will also be provided with guidelines to best interpret the results. The Edulai Skills Checker assessment can be taken once or multiple times over a given period so that the development of one or more skills can be monitored over time.'
        }
      }
    },
    it: {
      translation: {
        general: {
          loading: 'Caricamento...',
          today: 'Oggi'
        },
        header: {
          users: 'Utenti',
          quiz: 'Test',
          questions: 'Domande',
          statistics: 'Statistiche',
          skills: 'Skills e Sotto skills',
          settings: 'Impostazioni',
          domain: 'Informazioni dominio'
        },
        skills: {
          COLLABORATION: 'Collaborazione',
          CRITICAL_THINKING: 'Pensiero Critico',
          PROBLEM_SOLVING: 'Problem Solving',
          LEADERSHIP: 'Leadership',
          COMMUNICATION: 'Comunicazione',
          INTERCULTURALISM: 'Interculturalità',
          LEARNING: 'Apprendimento',
          systemSkills: 'Skills di sistema',
          createNewSkill: 'Nuova Skill',
          noSkillFound: 'Nessuna Skill trovata a sistema',
          systemSubSkill: 'Sotto-Skill',
          noSubSkillForSkill: 'Nessuna Sub-Skill definita per la Skill selezionata',
          systemSubSkills: 'Sotto-Skills',
          systemSubSkillsDescription: 'Definisci una o più Sotto-Skill associate alla Skill corrente'
        },
        skillsReport: {
          reportIntroductionTitle: 'Introduzione',
          reportIntroduction:
            'Edulai Skills Checker© è un software del pacchetto Edulai concepito per misurare le competenze degli utenti, attraverso la somministrazione di casi professionali specifici.\n\n Lo scopo di Edulai Skills Checker© è quello di valutare su una scala da 1 (base) a 5 (esperto) il livello delle competenze e delle sotto competenze trasversali possedute, strumenti chiave oggi per il mercato del lavoro del futuro.\n\n Lo Skills Checker© sottopone dei Casi professionali che l’utente dovrà risolvere scegliendo una risposta tra diverse opzioni possibili, riflettendo sui comportamenti che possono essere più vicini alla propria esperienza personale o che utilizzerebbe calandosi nelle diverse situazioni \n\n',
          reportSkillsListIntro: 'Le Competenze valutate da questo Test sono le seguenti:',
          reportSkillsValueListIntro:
            'La scala utilizzata per la valutazione comprende i seguenti livelli/stelle per ogni competenza:',
          reportIntroductionEnd:
            'Una volta effettuata la valutazione, il sistema offre accesso ad un manuale guida per la lettura dei propri risultati e delle definizioni delle competenze.',
          reportIntroductionSkillsValue:
            '• 1 stella – Base\n• 2 stelle – Principiante\n• 3 stelle – Intermedio\n• 4 stelle – Avanzato\n• 5 stelle – Esperto',
          skillsResultsTitle: 'RISULTATI SKILLS CHECKER PER OGNI COMPETENZA & SOTTO-COMPETENZA',
          skillsChartResultsTitle: 'RISULTATI SKILLS CHECKER',
          skillsResultsValueTitle: 'VALORE DI RIFERIMENTO (1-5)',
          strenghts: 'Punti di forza',
          weaknesses: 'Aree da potenziare',
          areasDevelopments: 'Ambiti di sviluppo',
          noWeaknesses: 'Il report non mostra al momento attuale aree particolari da potenziare.',
          noStrenghts: 'Il report non mostra al momento particolari punti di forza da segnalare.',
          noAreasDevelopments: 'Il report non mostra al momento attuale ambiti di sviluppo.',
          subSkillStrenghtMessage:
            'Il report ha diversi punti di forza e sotto-competenze di medio-alto livello soprattutto per <strong>{{ subSkills }}</strong>. Pertanto dimostri delle potenzialità in tali ambiti.',
          subSkillWeaknessLowMessage:
            'Il report mostra delle aree di miglioramento soprattutto per sotto-competenze come <strong>{{ subSkills }}</strong>. ',
          subSkillWeaknessMediumMessage:
            'Anche competenze che sono di livello 3 come <strong>{{ subSkills }}</strong> potrebbero essere potenziate seppur partano da un buon livello iniziale.',

          skillStrenghtMessage:
            'Il report mostra diversi punti di forza e competenze di medio-alto livello soprattutto per <strong>{{ skills }}</strong>. Dimostri dunque delle potenzialità in tali ambiti.',
          skillWeaknessLowMessage:
            'Il report mostra delle aree di miglioramento soprattutto per competenze come <strong>{{ skills }}</strong>. ',
          skillWeaknessMediumMessage:
            'Anche competenze come <strong>{{ skills }}</strong> potrebbero essere potenziate seppur partano da un buon livello iniziale.',

          skillReport1:
            'Il report mostra la presenza di competenze come <strong>{{ skills }}</strong> che hai auto-valutato in modo inferiore rispetto alla valutazione effettuata dal test. Queste in particolare, sono aree che richiedono un certo grado di approfondimento soprattutto in merito alla percezione che hai di te stesso/a e delle tue aree di miglioramento. Per le competenze auto-valutate con i punteggi vicini o di poco superiori al livello del test, hai dimostrato di avere buona consapevolezza e conoscenza di te.',
          skillReport2:
            'Il report mostra la presenza di competenze come  <strong>{{ skills }}</strong> che hai auto-valutate in modo superiore rispetto alla valutazione effettuata dal test. Queste in particolare, sono aree che richiedono un certo grado di approfondimento soprattutto in merito alla percezione che hai di te stesso/a e dei tuoi punti di forza. Per le competenze auto-valutate con punteggi vicini o di poco inferiori al livello del test, hai dimostrato di avere buona consapevolezza e conoscenza di sé.',
          skillReport3:
            'Il report mostra la presenza di competenze come  <strong>{{ skills }}</strong> che ti sei auto-valutate in linea con la valutazione effettuata dal test. Queste in particolare, sono aree che dimostrano un buon grado di consapevolezza dei tuoi punti di forza e debolezza. Le competenze che hai auto-valutato con i punteggi superiori/inferiori al livello del test, richiedono un certo grado di approfondimento soprattutto in merito alla percezione che hai di sè stesso/a dei tuoi limiti e potenzialità.',
          skillReport4:
            'Il report mostra la presenza di competenze come <strong>{{ skills1 }}</strong> che ti sei auto-valutate in modo superiore rispetto alla valutazione effettuata dal test. Queste in particolare, sono aree che richiedono un certo grado di approfondimento soprattutto in merito alla percezione che hai di te stesso/a e dei tuoi punti di forza. Hai anche una buona parte di competenze come <strong>{{ skills2 }}</strong> che ti sei auto-valutate in modo inferiore rispetto alla valutazione effettuata dal test. Anche queste richiedono un certo grado di approfondimento soprattutto in merito alla percezione che hai di sè stesso/a e delle tue aree di miglioramento.',

          // WORKWROUND
          COLLABORATION:
            'Migliorare la percezione personale che si ha rispetto al lavoro in team, considerandola una risorsa preziosa a cui attingere per la realizzazione di un compito anche se a volte richiede un maggior investimento di tempo. Spesso il contributo variegato che possono dare tutti i membri di un gruppo può essere arricchente, altre volte può essere fonte di conflitti. Allo stesso tempo delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili.',
          PROBLEM_SOLVING:
            'Migliorare e variegare il proprio approccio alla risoluzione dei problemi. Spesso non esiste un unico modo per risolvere quel tipo di problema. La creatività ed il pensiero critico aiutano a sviluppare le nostre capacità di analisi della situazione per poi trovare la o le soluzioni più adatte. La ricerca di una soluzione anche originale richiede anche del tempo e un certo grado di riflessione. Esistono diversi metodi e approcci alla risoluzione dei problemi: delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili.',
          LEADERSHIP:
            'Migliorare il proprio approccio alla leadership, spesso richiede la capacità di accettare feedback costruttivi dagli altri così come la capacità di offrirne. La leadership è un continuo processo di apprendimento dove il leader spesso crea un clima di fiducia e di valorizzazione dei talenti altrui. Per sviluppare il proprio lavoro e responsabilità il leader si basa sulla collaborazione spontanea dei propri colleghi, ed un buon leader è in grado di potenziare questa collaborazione senza forzature di sorta. Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come la gestione di gruppi di lavoro.',
          COMMUNICATION:
            'Migliorare il proprio approccio alla comunicazione, spesso richiede la capacità di chiarirne bene il contesto e lo scopo. Spesso il contenuto della comunicazione deriva da conoscenze e convinzioni proprie, altre volte può essere supportata da fonti o risorse esterne (letture, ricerche, report, etc. ). Nella comunicazione è importante comprendere, ascoltare ed in un secondo momento comunicare anche in base all’interlocutore che si ha davanti (uomo, donna, referente, dipendente che sia). Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come la pratica quotidiana e lo scambio continuo con gli altri.',
          INTERCULTURALITY:
            'Migliorare il proprio approccio verso culture diverse, spesso richiede la capacità di aprirsi verso l’altro verso il nuovo e saper ascoltare. La curiosità verso ciò che non si conosce aiuta, così come l’empatia, come la capacità di mettersi nei panni degli altri per capire meglio. La conoscenza e comprensione dei propri BIAS e limiti (essere cresciuti in un ambiente chiuso, non essere stati esposti a culture differenti) è altrettanto importante per sviluppare questa competenza. Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come lo scambio e l’entrare a contatto con persone di culture diverse.',
          LEARNING:
            'Migliorare il proprio apprendimento significa essere sempre aperti al nuovo. Il processo di apprendimento è continuo, non ha un inizio ed una fine e essere continuamente stimolati dalla conoscenza aiuta a non smettere mai d’imparare. Ciò implica anche l’essere in grado di monitorare il proprio percorso e di modificarlo se necessario in base alle proprie necessità; così come l’apprendimento quotidiano ci chiede di capire che cosa sia più importante per noi e dove vogliamo porre la nostra attenzione durante questo percorso senza fine. Delle sessioni formative per migliorare questa competenza potrebbero essere utili, così come effettuare una riflessione su ciò che sia più importante per noi tra gli stimoli che riceviamo ogni giorno.',
          CRITICAL_THINKING:
            'Migliorare il proprio pensiero critico non è semplice e spesso richiede buone capacità di riflessione e capacità analitiche, ossia di analisi di una situazione o di un problema. Ciò significa anche pensare in modo alternativo, non avere paura di portare idee nuove in campo, a volte anche in contrasto con quelle degli altri. Allo stesso modo questa esercitazione è chiave applicarla sulle conclusioni identificate e sulle implicazioni e conseguenze trovate. Delle sessioni formative per migliorare questa competenza ed allenarla, potrebbero essere utili, così come la pratica del pensiero alternativo che analizza una situazione, ne valuta approcci diversi traendo conclusioni e conseguenze successive.',
          RESULT_ORIENTATION:
            'Questa competenza richiede l’essere in grado di dare priorità ai propri obiettivi così come individuare ed applicare strategie adatte per raggiungerli, perseverando. E’ una competenza chiave che si può sviluppare con l’esercitarsi nel mettere in ordine d’importanza gli obiettivi quotidiani e nel riflettere individuando le strategie adatte per raggiungerli. E’ inoltre importante non scoraggiarsi di fronte all’insuccesso ma considerarlo come un momento di apprendimento per il tentativo successivo. Coaching e formazione possono sicuramente venire in aiuto per lo sviluppo di questa competenza.',
          ASSERTIVENESS:
            'L’assertività è la competenza che ci permette di praticare un atteggiamento o una comunicazione, non troppo aggressiva o passiva con gli altri. Allenarsi ad essere chiari ed aperti senza ferire nessuno può essere una buona strategia, così come provare a prendere decisioni con gli altri senza aggressività o passività del caso. Spesso la miglior soluzione è la pratica della comunicazione assertiva nelle relazioni, un allenamento nel comunicare nel modo giusto e ricevere feedback dal nostro interlocutore. Nello stesso tempo delle sessioni formative o di coaching potrebbero essere utili per migliorarla.',
          RESILIENCE:
            'La resilienza è una competenza chiave del mercato del lavoro ed è oggi più che mai richiesta. Per svilupparla è importante imparare a non farsi abbattere dagli ostacoli, grandi o piccoli che siano, così come è importante esercitare la perserveranza e l’adattamento, la flessibilità nella risoluzione dei problemi. E’ importante non farsi abbattere anche dagli ostacoli e tentare, anche per prova ed errori, nel loro superamento. Armarsi di pazienza e di fiducia è chiave così come di ottimismo ed energia. Delle sessioni formative o di coaching potrebbero essere utili per migliorarla, così come la pratica quotidiana del superamento dei problemi o del superamento di ostacoli che perdurano.',
          DIGITAL_COLLABORATION:
            'Migliorare la percezione personale che si ha rispetto al lavoro in team da remoto, considerandola una risorsa preziosa a cui attingere per la realizzazione di un compito anche se a volte richiede un maggior investimento di tempo soprattutto quando si lavora a distanza. Spesso il contributo variegato che possono dare tutti i membri di un gruppo può essere arricchente, altre volte può essere fonte di conflitti. Allo stesso tempo delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili.',
          DIGITAL_PROBLEM_SOLVING:
            'Migliorare e variegare il proprio approccio alla risoluzione dei problemi. Spesso non esiste un unico modo per risolvere quel tipo di problema. La creatività ed il pensiero critico aiutano a sviluppare le nostre capacità di analisi della situazione per poi trovare la o le soluzioni più adatte, soprattutto nel momento in cui si ha a che fare con la tecnologia. La ricerca di una soluzione anche originale e la sua implementazione richiede anche del tempo e un certo grado di riflessione, così come riuscire ad avere un rapporto sereno con la tecnologia Esistono diversi metodi e approcci alla risoluzione dei problemi: delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili.',
          DIGITAL_LEADERSHIP:
            'Migliorare il proprio approccio alla leadership digitale, spesso richiede una certa capacità organizzativa e di autonomia nel proprio lavoro, la capacità di definire i compiti del proprio gruppo, identificarne le priorità e la loro misurazione anche quando si è a a distanza. La leadership è un continuo processo di apprendimento dove il leader spesso crea un clima di fiducia e di valorizzazione dei talenti altrui, anche quando la relazione con gli altri membri del team è mediata dalla tecnologia. Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come la gestione di gruppi di lavoro.',
          DIGITAL_COMMUNICATION:
            'Migliorare il proprio approccio alla comunicazione digitale, spesso richiede la capacità di chiarirne bene il contesto e lo scopo, anche di quella online. Occorre conoscere in parte anche i diversi tipi di tecnologie di comunicazione per scegliere quelli appropriati che siano più efficaci per la comunicazione che si vuole realizzare. Nella comunicazione è importante comprendere, ascoltare ed in un secondo momento comunicare anche in base all’interlocutore che si ha davanti (uomo, donna, referente, dipendente che sia), così come saper usare la tecnologia anche per presentare contenuti al pubblico (Presentazioni diverse). Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come la pratica quotidiana e lo scambio continuo con gli altri, attraverso la tecnologia.',
          AUTONOMY:
            'Migliorare la propria autonomia significa essere in grado di definire compiti, scadenze, obiettivi di una specifica attività in modo autonomo, indipendente, per poi realizzarli. L’autonomia richiede competenze organizzative importanti soprattutto nel momento in cui si lavora a distanza e non vi è un regolare contatto con gli altri in presenza. Richiede saper gestire il proprio tempo e le proprie risorse al meglio, così come il proprio team. Spesso è una competenza che va allenata provando a completare piccoli compiti da soli, per poi arrivare col tempo e gradualmente a gestirne di più complessi, rispettando delle scadenze decise. Delle sessioni formative o di coaching per migliorare questa competenza potrebbero essere utili, così come la pratica quotidiana nella gestione dei compiti di lavoro anche da remoto.',
          PROACTIVITY:
            'Migliorare la propria proattività significa essere in grado di anticipare e prevedere situazioni, problemi, fatti prima che accadano. La lungimiranza, la capacità di osservazione e di ascolto di chi e di ciò che ci sta attorno, aiutano a guidarci e a prevedere i fatti per poi agire poco prima o mentre accadono. Nel momento in cui si lavora da remoto, questa competenza ci chiede di osservare bene quello che accade nel digitale, nella comunicazione via email e nelle riunioni virtuali con gli altri. Sviluppare queste capacità così come l’apprendere dall’esperienza passata, è sicuramente chiave per potenziare questa competenza. Delle sessioni formative o di coaching  potrebbero essere utili per migliorarla, così come la pratica quotidiana dell’osservazione e dell’ascolto anche online.',
          EMPATY:
            'L’empatia, soprattutto quando si lavora da remoto, è una competenza chiave oggi. Essere in grado di immaginarsi nei panni degli altri o essere sensibili alle emozioni degli altri anche se non sono fisicamente con noi nello stesso luogo, è importante per le professioni del futuro. E’ chiave allenarsi a mettere al centro la persona, la relazione e provare a “capire” ad “ascoltare” anche se gli altri non sono fisicamente vicino a noi. Delle sessioni formative o di coaching potrebbero essere utili per migliorarla, così come provare a focalizzarsi sull’altro e non su di noi o sulla tecnologia, quando si lavora da remoto, praticando l’arte dell’ascolto e dell’osservazione.',
          DIGITAL_TECHNOLOGY:
            'Utilizzare le tecnologie digitali può rappresentare spesso un ostacolo, soprattutto quando si lavora a distanza o da remoto. La pratica quotidiana nella comunicazione via email, via video-conferenza, via messaggistica etc. può aiutare non solo a vincere particolari paure o ostacoli iniziali ma anche a migliorare l’utilizzo, il modo, gli scopi per cui vengono utilizzate le tecnologie. La pratica ci può aiutare a capire che ogni strumento tecnologico è adatto ad uno scopo preciso e che le tecnologie possono essere utilizzare per raggiungere in modo efficiente i nostri obiettivi. Ciò ci può aiutare ad essere più confidenti con le stesse, gestendo meglio situazioni di stress. Momenti di formazione ma anche la pratica regolare del loro utilizzo sono sicuramente d’aiuto.'
        },
        login: {
          noService: 'Servizio temporaneamente non disponibile. Per favore ricarica la pagina e riprova',
          welcome: 'Bentornato!',
          passwordRecovery: 'Password dimenticata?',
          passwordRecoveryBody:
            "Inserisci la tua email nel campo sottostante. Ti invieremo un'email con le istruzioni per il recupero password",
          loginError: 'Login fallito. Per favore controlla le tue credenziali e riprova',
          welcomeFirstTime: 'Imposta la tua password per accedere',
          confirmRegistrationError: "Si è verificato un errore nella creazione dell'account. Per favore riprova",
          creatingYourAccount: 'Stiamo creando il tuo nuovo account!',
          createAccountSuccess:
            'Account creato con successo. Controlla la tua posta elettronica per impostare la password ed effettuare il login',
          resetPasswordSuccess: "Un'email con le istruzioni di recupero è stata inviata!",
          resetPasswordError: 'Si è verificato un errore nel recupero password. Per favore riprova',
          passwordSetSuccess: 'Password impostata con successo! Vai al login per accedere',
          passwordSetError: "Si è verificato un errore nell'impostare la password. Per favore riprova",
          insertEmail: 'La tua email',
          insertPassword: 'La tua password',
          registerNow: 'Non hai un account? Registrati adesso!',
          registerUserBody: 'Inserisci i tuoi dati per creare il tuo account Edulai!',
          registerUserEmail: "Ti invieremo un'email con le istruzioni per impostare la tua password",
          createNewAccount: 'Crea un nuovo account',
          insertFirstName: 'Il tuo nome',
          insertLastName: 'Il tuo cognome',
          acceptPrivacy: 'Ho letto e accetto la Privacy Policy',
          passwordRecoveryForUser: 'Invia email di recupero password'
        },
        modals: {
          cancel: 'Cancella',
          continue: 'Continua',
          confirm: 'Conferma',
          ok: 'Ok',
          compliment: 'Complimenti',
          publish: 'Pubblica',
          close: 'Chiudi',
          delete: 'Elimina',
          save: 'Salva',
          reloadPage: 'Ricarica Pagina',
          closeWithoutSave: 'Chiudi senza salvare'
        },
        languages: {
          language: 'Lingua',
          it: 'Italiano',
          en: 'Inglese',
          ITALIAN: 'Italiano',
          ENGLISH: 'Inglese',
          selectLanguage: 'Seleziona lingua'
        },
        user: {
          profile: 'Profilo',
          ageRange: "Fascia d'età",
          gender: 'Genere',
          nation: 'Nazione',
          employment: 'Impiego',
          professionName: 'Professione',
          experienceAgeRange: 'Anni di esperienza',
          educational: 'Livello istruzione',
          portfolioDescription: 'Portfolio',
          employmentsTypes: {
            STUDENT: 'Studente',
            UNEMPLOYED: 'Disoccupato',
            EMPLOYED: 'Impiegato'
          },
          ageRangeTypes: {
            RANGE18_22: '18-22 anni',
            RANGE23_25: '23-25 anni',
            RANGE26_30: '26-30 anni',
            RANGE31_40: '31-40 anni',
            RANGE41_50: '41-50 anni',
            RANGE51_99: '50+ anni'
          },
          experienceAgeRangeTypes: {
            RANGE0_1: 'Meno di un anno',
            RANGE1_2: '1-2 anni',
            RANGE2_5: '2-5 anni',
            RANGE5_10: '5-10 anni',
            RANGE10_20: '10-20 anni',
            RANGE20_99: 'Più di 20 anni'
          },
          genderTypes: {
            MALE: 'Maschio',
            FEMALE: 'Femmina',
            UNSPECIFIED: 'Non specificato'
          },
          educationalTypes: {
            SECONDARY_SCHOOL: 'Scuola superiore',
            GRADUATION: 'Diploma/Laurea Universitaria',
            POSTGRADUATE_CERTIFICATE: 'Certificazione post-universitaria'
          },
          onboardingUpdatedAt: 'Profilo aggiornato al {{date}}'
        },
        forms: {
          copySuccess: 'Link copiato negli appunti',
          inactiveUserActivationLink: 'Link di attivazione',
          inactiveUserExplanation: "L'utente non ha ancora attivato il suo account",
          inactiveUserCopyLink:
            "Se l'utente ha difficoltà ad attivare il suo account puoi inviargli questo link di attivazione",
          inactiveUserCopyWarning:
            "Attenzione: utilizza questo link solo in casi di estrema necessità. Verifica che l'utente abbia inserito un'email valida e di star inviando il link all'account corretto. Una volta in possesso del link di attivazione, chiunque potrà attivare questo account anche in maniera erronea",
          selectSkill: 'Seleziona una skill',
          today: 'Oggi',
          thisMonth: 'Questo mese',
          thisYear: "Quest'anno",
          last6Months: 'Ultimi 6 mesi',
          thisWeek: 'Questa settimana',
          edulaiTagNotModify: 'Il gruppo Edulai non può essere modificato',
          tagName: 'Nome gruppo',
          selectSkillsToCreateAnswers:
            'Seleziona almeno due skills e due sottoskills per iniziare a creare le risposte',
          badgeLinksDescription: 'Link a badge digitali',
          forceUserOnboardingDescription: "Forza l'utente ad effettuare nuovamente l'onboarding",
          forceUserOnboarding: 'Forza onboarding',
          enableUser: 'Attiva utente',
          selfAssessmentDescription:
            'Indica il livello di padronanza che ritieni di avere per ogni skill indicando un valore da 1 a 5, dove 1 indica il livello Base e 5 quello di Esperto',
          saveDraft: 'Salva bozza test',
          backtoLogin: 'Torna al login',
          noOptions: 'Nessuna opzione',
          loadingOptions: 'Caricando opzioni',
          companyRolePlaceholder: 'Cerca ruolo...',
          required: 'Obbligatorio',
          save: 'Salva',
          search: 'Cerca',
          reset: 'Cancella',
          name: 'Nome',
          edit: 'Modifica',
          send: 'Invia',
          createNew: 'Crea',
          saveChanges: 'Salva modifiche',
          firstName: 'Nome',
          lastName: 'Cognome',
          next: 'Prossimo',
          back: 'Indietro',
          skip: 'Salta',
          finish: 'Finito',
          optional: 'Opzionale',
          confirmPassword: 'Conferma password',
          newPassword: 'La tua password',
          passwordValidation: 'Minimo 8 caratteri e almeno una lettera. Niente caratteri speciali',
          passwordMustCoincide: 'Le password devono coincidere',
          passwordSpecialCharacters: 'Nessun carattere speciale',
          warning: 'Attenzione',
          close: 'Chiudi',
          emailNotValid: 'Email non valida',
          logoutConfirmation: 'Sei sicuro di voler effettuare il logout?',
          insertFirstName: 'Il tuo nome',
          insertLastName: 'Il tuo cognome',
          iAcceptThe: 'Ho letto e accetto la',
          privacyPolicy: 'Privacy Policy',
          confirmPasswordLabel: 'Conferma la tua password',
          confirmRegistration: 'Crea il tuo account',
          confirmPasswordTitle: 'Conferma password',
          userRole: 'Ruolo utente',
          deleteUser: 'Cancella utente',
          disableUserExplanation:
            "Disabilita l'utente per revocare tutti i suoi permessi all'interno della piattaforma",
          enabledUserExplanation: "L'utente è stato disabilitato",
          userStatus: "Stato dell'utente",
          onboardingTitle: 'Benvenuto in Edulai!',
          onboardingDescription:
            'Ti diamo il nostro benvenuto in <strong>Edulai Skills Checker</strong> Prima di iniziare ti chiediamo un paio di minuti per conoscerti meglio. Come valuti le tue competenze? Prenditi il tuo tempo per rispondere e sii sincero con te stesso. Adesso cominciamo!',
          whatIsYourAge: 'Quanti anni hai?',
          startEdulai: 'Comincia a usare Edulai!',
          whatIsYourGender: 'Qual è il tuo genere?',
          male: 'Uomo',
          female: 'Donna',
          other: 'Altro',
          insertNation: 'Inserisci la tua nazione',
          whatIsYourNation: 'Qual è la tua nazione di residenza?',
          whatIsYourOccupancy: 'Attualmente sei',
          student: 'Studente',
          unemployed: 'Disoccupato',
          professionist: 'Un professionista occupato',
          whatIsYourJob: 'Se sei occupato, qual è il nome del tuo ruolo professionale principale?',
          insertJobTitle: 'Inserisci il nome del tuo ruolo',
          yearsOfExperince: 'Quanti anni di esperienza professionale hai in quel ruolo?',
          insertYearsOfExperince: 'Inserisci anni di esperienza',
          years: 'Anni',
          lessThanOneYear: 'Meno di un anno',
          moreThanTwentiYear: 'Più di 20 anni',
          whatIsYourScholarLevel: 'Qual è il tuo livello scolastico?',
          highSchool: 'Scuola superiore',
          universityDegree: 'Diploma/Laurea Universitaria',
          postUniversityDegree: 'Certificazione post-universitaria',
          stageOrInsterests:
            "Hai mai svolto stage di qualunque tipo, studi o lavoro all'estero, o hai degli interessi personali che ti hanno aiutato a sviluppare delle competenze per il mercato del lavoro? In caso affermativo seleziona le competenze che hai maturato e spiega qui sotto attraverso quale esperienze sono state acquisite. Se del caso potrai discutere queste competenze direttamente con i datori di lavoro",
          stageOrInsterestsSupport:
            'Indica in questa casella se hai del materiale prodotto a supporto delle competenze maturate che hai elencato qui sopra, come parte del tuo portfolio professionale da mostrare ai datori di lavoro ed indica di che materiale si stratta (Es. registrazioni video di tue presentazioni, elaborati scritti o progetti da te realizzati, etc)',
          stageOrInsterestsBadges:
            'Hai mai ottenuto badge digitali o certificazioni per una o più delle competenze qui sopra riportate? In caso affermativo indica per quale/quali competenza/competenze li hai maturati? Fornisci per favore qui sotto anche il/i link al/ai badge, se del caso',
          skillName: 'Nome skill',
          newSkillWarning:
            'La nuova skill sarà disponibile solo per la creazione o la modifica di nuove domande. Le modifiche non coinvolgeranno Test già creati o in corso',
          skillCreatedSuccess: 'Skill creata con successo',
          skillCreatedError: 'Si è verificato un errore nella creazione della Skill. Per favore riprova',
          skillUpdatedSuccess: 'Skill aggiornata con successo',
          skillUpdatedError: "Si è verificato un errore nell'aggiornamento della Skill. Per favore riprova",
          skillDeleteWarning:
            "Eliminando questa Skill, la renderai non disponibile per i nuovi Test. Test precedenti che includono questa Skill non saranno interessati da questo cambiamento e l'abilità sarà comunque visibile nelle statistiche",
          skillDeleteError: 'Si è verificato un errore nella cancellazione della Skill. Per favore riprova',
          skillDeleteSuccess: 'Skill cancellata con successo',
          subSkills: 'Sub-Skills',
          newSubSkill: 'Nuova Sub-Skill',
          subSkillCreatedSuccess: 'Sub-Skill creata con successo',
          subSkillCreatedError: 'Si è verificato un errore nella creazione della Sub-Skill. Per favore riprova',
          subSkillUpdatedSuccess: 'Sub-Skill aggiornata con successo',
          subSkillUpdatedError: "Si è verificato un errore nell'aggiornamento della Sub-Skill. Per favore riprova",
          subSkillDeleteWarning:
            "Eliminando questa Sub-Skill, la renderai non disponibile per i nuovi Test. Test precedenti che includono questa Sub-Skill non saranno interessati da questo cambiamento e l'abilità sarà comunque visibile nelle statistiche",
          subSkillDeleteError: 'Si è verificato un errore nella cancellazione della Sub-Skill. Per favore riprova',
          subSkillDeleteSuccess: 'Sub-Skill cancellata con successo',
          deleteSubSkill: 'Cancella Sotto-Skill',
          deleteSkill: 'Cancella Skill',
          expirationDate: 'Data di scadenza',
          creationDate: 'Data di creazione',
          credits: 'Crediti residui',
          subscriptionType: 'Tipo di licenza',
          addCredits: 'Aggiungi crediti',
          setCredits: 'Imposta crediti',
          totalCredits: 'Crediti totali',
          addCreditsDescription: "Modifica i crediti dell'istituzione",
          addCreditSuccess: 'Crediti modificati con successo',
          addCreditError: 'Si è verificato un errore nella modifica dei crediti. Per favore riprova',
          creditsLabel: 'Crediti',
          modifyDomainType: 'Modifica tipo di licenza',
          modifyDomainTypeDescription:
            'Puoi modificare il tipo di lincenza dominio in Licenza Flat (crediti illimitati) e Licenza PPU (crediti limitati).',
          modifyDomainTypeWarning: 'Sei sicuro di voler modificare la licenza?',
          modifyDomainTypeSuccess: 'Licenza modificata con successo',
          modifyDomainTypeError: 'Si è verificato un errore nella modifica della licenza. Per favore riprova',
          flatSubscriptionDescription: 'Flat (crediti illimitati)',
          ppuSubscriptionDescription: 'PPU (crediti limitati)',
          flatCreditsDescription:
            'Nei domini di tipo FLAT i crediti residui non verranno scalati quando vengono inviate nuove rispose ai Test',
          domainStatus: 'Stato Dominio',
          disabled: 'Disabilitato',
          enabled: 'Attivo',
          disableDomain: 'Disabilita Dominio',
          enableDomain: 'Attiva Dominio',
          disableDomainRequest:
            'Sei sicuro di voler disattivare il dominio? Non sarà più possibile creare nuovi utenti, nuovi Test e sottomettere risposte',
          modifyDomainStatusSuccess: 'Stato del dominio modificato con successo',
          modifyDomainStatusError: 'Si è verificato un errore nella modifica del dominio.',
          title: 'Titolo',
          text: 'Testo',
          evaluatedSkills: 'Skills valutate',
          startDate: 'Data di inizio',
          startTime: 'Ora di inizio',
          startDateTime: 'Date e ora di inizio',
          endDate: 'Data di fine',
          endTime: 'Ora di fine',
          endDateTime: 'Date e ora di fine',
          min1hr: 'Intervallo minimo di 1 ora',
          startBeforeEnd: 'Data di inizio successiva a data di fine',
          newEndBeforeOldEnd: 'Nuova data di fine predecente ad originale',
          quizInPast: 'Impossibile creare test nel passato',
          delete: 'Elimina',
          total: 'Totale',
          totals: 'Totali',
          creditsAfterOperations: "Crediti totali dopo l'operazione: {{credits}}",
          selectOperation: 'Seleziona operazione',
          modifyCredits: 'Modifica Crediti',
          userTagPlaceholder: "Seleziona un gruppo per l'utente",
          userTag: 'Gruppo utente',
          userTagDescription: 'Utilizza i gruppi per permettere di selezionare più utenti in maniera semplice',
          createNewOption: 'Crea nuovo',
          createNewTagDescription: 'Crea un nuovo gruppo per gli utenti',
          createNewTag: 'Crea nuovo gruppo',
          modifyTagDescription: 'Modifica il gruppo di utenti',
          deleteTagConfirm: "Sei sicuro di voler eliminare questo gruppo? L'operazione non può essere annullata",
          dateInterval: 'Intervallo di tempo',
          applyFilters: 'Applica filtri',
          resetFilters: 'Elimina filtri',
          stageOrInsterestsDescription: 'Abilità e esperienza in stage',
          edulaiTagDefaultDescription: "Se non selezioni nessun gruppo, l'utente apparterrà al gruppo di base Edulai",
          editSkillName: 'Modifica nome skill',
          cantDeleteReferencedSkill:
            'Impossibile eliminare la skill selezionata. La skill è referenziata da una o più domande.',
          deleteQuestionWarning:
            'Eliminando questa Domanda la renderai non disponibile per i nuovi Test. Test che includono una versione precedente di questa Domanda non saranno interessati da questo cambiamento.',
          deleteQuestionSuccess: 'Domanda eliminata',
          deleteQuestionError: 'Si è verificato un errore nella cancellazione della domanda',
          deleteQuestionInUseError: 'Errore: questa versione della domanda è in uso in un Test',
          maxCharacters: 'Massimo {{number}} caratteri'
        },
        domainTypes: {
          FLAT: 'Licenza FLAT',
          PPU: 'Licenza a consumo crediti'
        },
        users: {
          inactiveUser: 'Utenti inattivi',
          tagUsers: 'Utenti',
          deleteTagSuccess: 'Gruppo eliminato',
          deleteTagError: 'Si è verificato un errore nel cancellare il gruppo',
          createUserTagSuccess: 'Gruppo creato con successo',
          createUserTagError: 'Si è verificato un errore nella creazione del gruppo',
          editUserTagSuccess: 'Gruppo modificato',
          editUserTagError: 'Si è verificato un errore nella modifica',
          userData: 'Dati utente',
          userProfile: 'Profilo utente',
          platformUsers: 'Utenti piattaforma',
          createNewUser: 'Nuovo utente',
          userEmail: "Email dell'utente",
          noUsersFound: 'Nessun utente trovato',
          disabled: 'Disabilitato',
          disableUser: 'Disabilita utente',
          enabled: 'Attivo',
          userModifySuccess: 'Profilo aggiornato con successo',
          userCreationError: "Si è verificato un errore nella creazione dell'utente. Per favore riprova",
          userDeleteError: "Si è verificato un errore nella cancellazione dell'utente. Per favore riprova",
          userModifyError: "Si è verificato un errore nella modifica dell'utente. Per favore riprova",
          modifyUserSuccess: 'Utente modificato con successo',
          createUserSuccess:
            "Utente creato con successo, L'utente risulterà INATTIVO fin quando non attiverà il suo account creando la sua password personale",
          deleteUserConfirm:
            "Sei sicuro di voler eliminare l'utente selezionato? L'operazione non può essere annullata",
          userDeleteSuccess: 'Utente eliminato con successo',
          platformUser: 'Utente piattaforma',
          disableUserSuccess: 'Utente disabilitato con successo',
          enableUserSuccess: 'Utente abilitato con successo',
          disableUserError: 'Si è verificato un errore. Per favore riprova',
          resendConfirmationEmail: 'Reinviga email di conferma',
          resendActivationEmail: 'Invia email di attivazione',
          activeUsers: 'Utenti attivi',
          disabledUsers: 'Utenti disabilitati',
          onboardingFailed: "Si è verificato un errore nell'invio dei dati. Per favore riprova",
          usersTags: 'Gruppi di utenti',
          noUsersTagFound: 'Nessun gruppo trovato',
          inactive: 'Inattivo'
        },
        questions: {
          newQuestion: 'Nuova domanda',
          editQuestion: 'Modifica domanda',
          findInTitle: 'Cerca nel titolo',
          noQuestionsFound: 'Nessuna domanda trovata',
          createQuestionSuccess: 'Domanda creata con successo',
          createQuestionError: 'Si è verificato un errore durante la creazione della domanda. Per favore riprova.',
          modifyQuestionSuccess: 'Domanda modificata con successo',
          modifyQuestionError: 'Si è verificato un errore durante la modifica della domanda. Per favore riprova.',
          answers: 'Risposte',
          newAnswer: 'Nuova risposta',
          editAnswer: 'Modifica riposta',
          deleteOptionConfirm:
            "Sei sicuro di voler eliminare l'opzione selezionata? L'operazione non può essere annullata",
          optionDeleteSuccess: 'Opzione eliminata con successo',
          optionsNumberError: 'Per favore, inserire almeno 3 risposte valide.',
          firstOptionCreate:
            'Dopo aver creato la prima risposta non sarà più possibile modificare skills e sottoskills senza resettare tutte le risposte già create. Sei sicuro di voler procedere?',
          myAnswers: 'Le mie risposte',
          version: 'Versione'
        },
        roles: {
          ROOT: 'Root',
          ADMIN: 'Admin',
          SUPERVISOR: 'Supervisore',
          USER: 'Utente base',
          PRINCIPAL: 'Principal'
        },
        rolesDescriptions: {
          ROOT: 'Questo ruolo è dedicato ai gestori della piattaforma cloud di Edulai',
          ADMIN:
            "L'utente Admin è l'utenza di più alto livello gerarchico dell'istituzione ed è responsabile della gestione degli utenti della piattaforma,",
          PRINCIPAL:
            "Il Principal è l'amministratore dell'istituzione il cui scopo è visualizzare l'andamento dello svolgimento dei test e l'avanzamento delle skills di tutta l'istituzione",
          SUPERVISOR:
            "L'utente Supervisor è l'utente di backoffice dell'istituzione il cui compito è creare e somministrare test",
          USER: "L'utente base della piattaforma ha la possibilità di compilare test, visualizzare i propri risultati per un singolo test e vedere l'andamento delle sue Skills e Sotto-Skills dal suo profilo"
        },
        domain: {
          domain: 'Dominio',
          customFields: 'Campi anagrafici personalizzati',
          createCustomField: 'Crea campo anagrafico',
          customFieldDescription:
            'Questo campo anagrafico verrà mostrato agli utenti come domanda in fase di onboarding',
          customFieldTitle: 'Nome del campo anagrafico',
          customFieldBody: 'Testo della domanda',
          customFieldSuccess: 'Campi anagrafici modificati con successo',
          customFieldError: 'Si è verificato un errore nella modifica dei campi anagrafici',
          deleteCustomFieldRequest: "Sei sicuro di voler eliminare questo campo? L'operazione non può essere annullata",
          customFieldDeleteSuccess: 'Campo eliminato con successo',
          customFieldDeleteError: "Si è verificato un errore nell'eliminazione"
        },
        quizzes: {
          types: {
            DRAFT: 'BOZZA',
            RUNNING: 'ATTIVO',
            PAUSED: 'IN PAUSA',
            DONE: 'FINITO',
            READY: 'PRONTO',
            ALL: 'TUTTI',
            EXPIRED_FOR_USER: 'SCADUTO'
          },
          typesDescriptions: {
            DRAFT: 'Le bozze dei test possono essere modificati. Clicca per attivarlo!',
            RUNNING: 'Il test è attivo e gli utenti possono rispondere alle domande',
            PAUSED: 'Il test è in pausa e gli utenti non possono rispondere alle domande',
            DONE: 'Il test è terminato!',
            READY: 'Il Test inizierà quando passerà la sua ora di inizio o se avviato prima del tempo',
            EXPIRED_FOR_USER: 'Il test è terminato e non hai inviato le tue risposte'
          },
          selectSupervisor: 'Seleziona un Supervisore',
          selectSupervisorDescription: 'Se non selezioni un supervisore solo tu sarai in grado di gestire il Test!',
          noCreditsError: 'Crediti del dominio terminati! Aggiungi nuovi crediti per avviare i test!',
          activeQuizzes: 'Test attivi',
          disabledQuizzes: 'Test inattivi',
          newQuiz: 'Nuovo test',
          noQuizzesFound: 'Nessun test trovato',
          editQuiz: 'Modifica test',
          createQuizSuccess:
            'Test creato con successo. Il test rimarrà in stato di "Bozza" fino a quando non verrà avviato!',
          createQuizError: 'Si è verificato un errore durante la creazione del test. Per favore riprova.',
          participants: 'Partecipanti',
          users: 'Utenti',
          questions: 'Domande',
          selectedQuestionsNumber: 'Numero di domande selezionate',
          validity: 'Validità',
          completed: 'Completati',
          progress: 'Progresso',
          submit: 'Invia',
          submitDraft: 'Test pronto!',
          submitQuizError: "Si è verificato un errore durante l'invio del test. Per favore riprova.",
          quizSubmitSuccess:
            'Test inviato con successo. Il test rimarrà in stato "Pronto" fino alla sua data di inizio',
          pause: 'Pausa',
          resume: 'Riattiva',
          pauseQuizError: 'Si è verificato un errore durante la sospensione del test. Per favore riprova.',
          quizPauseSuccess: 'Test sospeso con successo',
          resumeQuizError: 'Si è verificato un errore durante la riattivazione del test. Per favore riprova.',
          quizResumeSuccess: 'Test riattivato con successo',
          changeStartDate: 'Modifica data di inizio',
          forceStart: 'Inizia ora',
          newStartDateTime: 'Nuova data e ora di inizio',
          changeStartDateError:
            'Si è verificato un errore durante la modifica della data e ora di inizio del test. Per favore riprova.',
          changeStartDateSuccess: 'Data e ora di inizio del test modificate con successo',
          confirmForceStart: 'Sei sicuro di voler iniziare il test immediatamente?',
          forceStartError: "Si è verificato un errore durante l'inizio del test. Per favore riprova.",
          forceStartSuccess: 'Test iniziato con successo',
          addNewParticipants: 'Aggiungi nuovi partecipanti',
          newParticipants: 'Nuovi partecipanti',
          addParticipantsSuccess: 'Nuovi partecipanti aggiunti con successo',
          addParticipantsError:
            "Si è verificato un errore durante l'aggiunta di nuovi partecipanti. Per favore riprova.",
          terminate: 'Termina',
          confirmTerminateQuiz: "Sei sicuro di voler terminare il test immediatamente? L'azione non sarà reversibile.",
          terminateQuizSuccess: 'Test terminato con successo',
          terminateQuizError: 'Si è verificato un errore durante la terminazione del test. Per favore riprova.',
          editQuizSuccess:
            'Test modificato con successo. Il test rimarrà in stato di "Bozza" fino a quando non verrà avviato!',
          editQuizError: 'Si è verificato un errore durante la modifica del test. Per favore riprova.',
          reactivate: 'Riattiva',
          confirmReactivateQuiz: 'Sei sicuro di voler riattivare il test?',
          reactivateQuizSuccess: 'Test riattivato con successo',
          reactivateQuizError: 'Si è verificato un errore durante la riattivazione del test. Per favore riprova.',
          createdBy: 'Responsabile',
          extendQuizSuccess: 'Test esteso con successo',
          extendQuizError:
            "Si è verificato un errore durante l'estensione della data e ora di inizio del test. Per favore riprova.",
          newEndDateTime: 'Nuova data e ora di fine',
          extendExpiration: 'Estendi scadenza',
          confirmDeleteQuiz: 'Sei sicuro di voler eliminare il test?',
          deleteQuizSuccess: 'Test eliminato con successo',
          deleteQuizError: "Si è verificato un errore durante l'eliminazione del test. Per favore riprova.",
          showQuestionsDetails: 'Mostra dettagli domande',
          givenAnswers: 'Risposte completate',
          totalQuestions: 'Domande totali',
          quizStatistics: 'Statistiche',
          userAnswers: 'Mostra risposte',
          sendReminder: 'Invia promemoria',
          sendReminderSuccess: 'Promemoria inviato',
          sendReminderError: 'Invio promemoria fallito',
          hideScoresToUsers: 'Nascondi punteggi ai partecipanti',
          usersCanDownloadReport: 'Gli utenti del test possono scaricare il loro report individuale',
          hideScores: 'Nascondi punteggi',
          downloadReport: 'Download Report',
          hideScoresDescription:
            'Se nascondi i punteggi gli utenti potranno rivedere le proprie risposte al test ma non i punteggi ottenuti',
          downloadReportDescription:
            'Se abiliti l’opzione per il download del report individuale, gli utenti potranno scaricare il proprio report. Tuttavia, se l’opzione ‘Nascondi Punteggi’ è attivata, i partecipanti al quiz non potranno scaricare il loro report.',
          hideScoreAnalyticsDescription: 'I punteggi del test sono nascosti dal supervisore',
          updateHideScoreSuccess: 'Nascondi punteggi modificato con successo',
          updateDownloadReportSuccess: 'Download report modificato con successo',
          updateHideScoreError:
            'Si è verificato un errore durante la modifica di nascondi punteggi. Per favore riprova.',
          updateDownloadReportError:
            'Si è verificato un errore durante la modifica di download report. Per favore riprova.'
        },
        analytics: {
          userScoresAsc: 'Dal peggiore al migliore',
          userScoresDesc: 'Dal migliore al peggiore',
          valutationRange: 'Range Valutazione',
          order: 'Ordinamento',
          changeView: 'Cambia visualizzazione',
          private: 'Privato e confidenziale',
          filters: 'Filtri',
          subskillsScores: 'Punteggi subskills',
          score: 'Punteggo',
          userScore: 'Punteggio utente',
          skillsScores: 'Punteggi skills',
          analytics: 'Statistiche',
          submissionsTrend: 'Andamento delle submissions',
          usersSubmissions: 'Risposte dei partecipanti',
          noSubmissionStarted: "L'utente non ha risposto al test",
          submissionStillDraft: "L'utente ha iniziato il test ma non ha finalizzato le risposte",
          currentScores: 'Punteggi del test',
          selfAssessmentScores: 'Autovalutazione iniziale',
          userQuizSkillsScore: 'Punteggi utente per il test',
          usersQuizSkillsScore: 'Punteggi utenti',
          skillsTrendChart: 'Grafico andamento delle skills',
          leastPerformingUser: 'Utente peggiore',
          bestPerformingUser: 'Utente migliore',
          skills: 'Skills',
          all: 'Qualsiasi',
          everyone: 'Tutti i partecipanti',
          errorCreateCSV: 'Si è verificato un errore nella creazione del CSV',
          errorCreatePDF: 'Si è verificato un errore nella creazione del PDF',
          chartForUser: "Statistiche dell'utente",
          chartForUserOverTime: "Statistiche dell'utente {{user}} nel tempo",
          chartForUserMean: "Media aggregata dell'utente {{user}}",
          chartMeanOverall: 'Media aggregata delle skills di sistema',
          chartMeanOverTime: "Media aggregata delle skills di sistema nell'intervallo di tempo selezionato",
          overallStatisticsForUser: "Punteggi totali dell'utente {{user}}",
          overallAnalytics: 'Metriche di sistema',
          overallAnalyticsOverTime: 'Andamento skills di sistema',
          overallAnalyticsOverTimeDescription:
            "Questo grafico mostra l'andamento delle skills di sistema nell'intervallo di tempo selezionato",
          currentScoresOfSystem: 'Punteggi attuali di tutti gli utenti',
          summaryDescription: 'Punteggi aggregati di tutti gli utenti della piattaforma per singola skill',
          userSummaryScores: 'Punteggi aggregati per ogni utente',
          userSummaryScoresDescription:
            'Questo grafico mostra per ogni utente la media dei suoi punteggi fino ad ora ottenuti',
          detailedAnalytics: 'Analitiche nel dettaglio',
          usersSkillsScores: 'Punteggi utenti per tutte le skills',
          selectSkillForChart: 'Seleziona la skill per vedere il punteggio degli utenti',
          overallStatisticsForUserDescription: 'Seleziona un utente dai filtri per visualizzarne i dettagli',
          userSkillsScore: 'Punteggio utente',
          usersSkillScores: 'Punteggi utenti per {{skill}}',
          userSummaryBubbleScoresDescription:
            'Questo grafico mostra per ogni classe di punteggio la percentuale di utenti con quel punteggio',
          percentage: 'Percentuale',
          userSummaryBubbleScores: 'Percentuali di utenti per punteggio',
          usersPercentage: 'Percentuale di utenti',
          downloadCsvSuccess: 'Il tuo report è stato inviato. Controlla la tua mail tra qualche minuto'
        },
        submissions: {
          answer: 'Risposta',
          submissionLoadFailed: 'Si è verificato un errore nel caricamento del test',
          pleaseReload: 'Per favore, ricarica il test.',
          submissionsFor: 'Le risposte al test {{quizName}}',
          closedAt: 'sono state chiuse il',
          arePaused: 'sono sospese.',
          waitForSupervisor: 'Attendi ulteriori istruzioni dal supervisor del test',
          willStart: 'inizieranno',
          soon: 'presto',
          areOpen: 'sono aperte',
          startNewSubmission: 'inizia il test',
          noAnswerSelectedError: 'Per favore, seleziona una delle opzioni.',
          submitAnswer: 'Invia risposta',
          concludeQuiz: 'Concludi test',
          answerSubmitSuccess: 'Risposta inviata con successo',
          answerSubmitError: "Si è verificato un errore durante l'invio della risposta. Per favore riprova.",
          quizSubmitted: 'Test inviato',
          submissionSubmitSuccess: 'Test concluso!',
          submissionSubmitError: 'Si è verificato un errore durante la chiusura del test. Per favore riprova.',
          sybmissionGuidelinesSmartWorking:
            'Con il termine “Smart Working” intendiamo lo svolgimento del proprio lavoro ovunque indipendentemente da dove ci si trovi e dalla localizzazione geografica, anche se esso può avvenire prevalentemente da casa. Questa definizione comprende anche la terminologia “lavoro da remoto” attraverso l’utilizzo delle tecnologie della comunicazione come ad esempio, PC, internet, email, sistemi di videoconferenza. per la realizzazione dei quotidiani compiti lavorativi e per lavorare con colleghi in team virtuali/online. (Smarter Working Guide, 2007)',
          submissionCompleted: 'Hai risposto a tutte le domande! Invia le tue risposte per terminare il test',
          noSubmissionSendQuizDone: 'Non hai inviato le tue risposte a questo test!',
          submissionsRefusedNoCredits:
            'La tua istituzione non può accettare nuove rispose. Per favore contatta il tuo responsabile',
          title:
            'Edulai Skills Checker valuta il livello di alcune tue Skills, strumenti chiave per affrontare oggi le sfide del mercato del lavoro.',
          description1:
            'Ti presenteremo diversi casi professionali concreti da leggere con attenzione. Ogni caso descrive una situazione specifica e dovrai scegliere, tra le risposte/soluzioni fornite, quella che ritieni più opportuna per quello specifico caso. Non pensarci troppo. Non esistono risposte giuste o sbagliate. Sono solo approcci diversi che ci aiutano a definire meglio le tue competenze.',
          description2:
            'La compilazione dello Skills Checker richiede tra i 15 e i 20 minuti. Le risposte devono essere fornite nella sequenza proposta. Non puoi saltarle né muoverti avanti e indietro tra le domande. Il test deve essere completato tutto in una volta, ma se problemi tecnici ti impedissero di completarlo avrai la possibilità di accedere nuovamente e di inviare le risposte mancanti. Una volta completate tutte le risposte, il sistema ti mostrerà i risultati e potrai accedere sia a un report più generale che a uno più specifico sullo sviluppo dei tuoi Skills. Ti saranno inoltre fornite le linee guida per interpretare al meglio i risultati. La valutazione di Edulai Skills Checker può essere eseguita una sola volta o anche più volte nell’arco di un dato periodo, così che lo sviluppo di una o più competenze possano essere monitorate nel tempo.'
        }
      }
    }
  }
});

export default i18next;
