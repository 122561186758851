import { withStyles, IconButton } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import { Field, FormSection, reduxForm } from 'redux-form';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import translations from '../../../translations/i18next';
import MDCheckBoxGroupField from '../FormsComponents/MDCheckbox/MDCheckBoxGroupField';
import MDRadioButtonsGroupField from '../FormsComponents/MDRadioButtonsGroupField/MDRadioButtonsGroupField';
import MDTextInputField from '../FormsComponents/MDTextInput/MDTextInputField';
import SubSkillScoreField from '../FormsComponents/SubSkillScore/SubSkillScoreField';
import { isMobileBrowser } from '../../../utils/utilsFunctions';
import { EDULAI_BLUE, EDULAI_PURPLE } from '../../../styles/styleConsts';

const validate = (values, initial) => {
  const errors = {};
  if (!values.ageRange) {
    errors.ageRange = translations.t('forms.required');
  }
  if (!values.gender) {
    errors.gender = translations.t('forms.required');
  }
  if (!values.nation) {
    errors.nation = translations.t('forms.required');
  }
  if (!values.employment) {
    errors.employment = translations.t('forms.required');
  }
  if (!values.educational) {
    errors.educational = translations.t('forms.required');
  }
  if (!values.skills) {
    errors.skills = translations.t('forms.required');
  }
  if (
    initial &&
    initial.values &&
    initial.values.skillsData &&
    values.skills &&
    _.size(_.compact(values.skills)) !== _.size(initial.values.skillsData)
  ) {
    errors.skills = translations.t('forms.required');
  }
  return errors;
};

const styles = (theme) => ({
  formContainer: {
    margin: isMobileBrowser() ? 0 : 20,
    marginTop: 20,
    marginLeft: 10,
    // height: '100vh',
    overflowY: 'scroll',
    padding: isMobileBrowser() ? 0 : 30,
    width: isMobileBrowser() ? window.screen.width + 150 : 'unset'
  },
  fieldTitle: {
    fontSize: 15,
    color: EDULAI_PURPLE
  },
  fieldSubtitle: {
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'italic'
  }
});

const theme = createMuiTheme({
  palette: {
    primary: { 500: EDULAI_PURPLE },
    secondary: { main: EDULAI_BLUE }
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    useNextVariants: true
  }
});

class OnboardingForm extends React.Component {
  async onFilterRoles(name) {
    const { roles } = this.props;
    const filteredOptions = _.filter(roles, (role) =>
      translations.t(`roles.${role.name}`).toLowerCase().includes(name.toLowerCase())
    );
    const newOptions = _.map(filteredOptions, (role) => ({
      value: String(role.id), // workaround for creatable component
      label: translations.t(`roles.${role.name}`)
    }));
    return newOptions;
  }

  render() {
    const { classes, customFields, currentForm, skills, instructionsLink } = this.props;
    const skillsField = _.map(skills, (skill) => (
      <Field
        key={skill.id}
        name={skill.id}
        containerstyle={{ marginBottom: 10 }}
        component={SubSkillScoreField}
        textStyle={{ fontSize: 17 }}
        label={skill.name}
        required
        isRadio
      />
    ));
    const isMobile = isMobileBrowser();
    return (
      <MuiThemeProvider theme={theme}>
        <div className={classes.formContainer}>
          <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <h4 style={{ margin: 5, fontSize: 17, color: EDULAI_PURPLE }}>
              {translations.t('forms.selfAssessmentDescription')}
            </h4>
            <span style={{ color: '#D71F4B' }}>*</span>
            <IconButton onClick={() => window.open(instructionsLink)}>
              <InfoIcon style={{ fontSize: 18, color: EDULAI_BLUE }} />
            </IconButton>
          </div>
          <FormSection name="skills">
            <div style={{ display: 'flex', flexDirection: 'column' }}>{skillsField}</div>
          </FormSection>
          {_.map(customFields, (customField, key) => (
            <div>
              <h4 className={classes.fieldTitle} style={{ marginBottom: 0 }}>
                {customField.title}
              </h4>
              <h4 className={classes.fieldSubtitle} style={{ marginTop: 5, marginBottom: 10 }}>
                {customField.body}
              </h4>
              <Field
                key={customField.id}
                name={customField.id}
                variant="outlined"
                component={MDTextInputField}
                containerstyle={{ marginBottom: 30 }}
              />
            </div>
          ))}
          <Field
            name="ageRange"
            component={MDRadioButtonsGroupField}
            containerstyle={{ marginBottom: 30, marginTop: 30 }}
            showErrorLabel
            horizontal={!isMobile}
            radioLabelStyle={{ fontSize: 16 }}
            title={translations.t('forms.whatIsYourAge')}
            required
            options={[
              {
                id: 'RANGE18_22',
                value: '18-22'
              },
              {
                id: 'RANGE23_25',
                value: '23-25'
              },
              {
                id: 'RANGE26_30',
                value: '26-30'
              },
              {
                id: 'RANGE31_40',
                value: '31-40'
              },
              {
                id: 'RANGE41_50',
                value: '41-50'
              },
              {
                id: 'RANGE51_99',
                value: '50+'
              }
            ]}
          />
          <Field
            name="gender"
            component={MDRadioButtonsGroupField}
            containerstyle={{ marginBottom: 30 }}
            showErrorLabel
            radioLabelStyle={{ fontSize: 16 }}
            title={translations.t('forms.whatIsYourGender')}
            required
            horizontal={!isMobile}
            options={[
              {
                id: 'MALE',
                value: translations.t('forms.male')
              },
              {
                id: 'FEMALE',
                value: translations.t('forms.female')
              },
              {
                id: 'UNSPECIFIED',
                value: translations.t('forms.other')
              }
            ]}
          />
          <h4 className={classes.fieldTitle}>
            {translations.t('forms.whatIsYourNation')}
            <span style={{ marginLeft: 2, color: '#D71F4B' }}>*</span>
          </h4>
          <Field
            name="nation"
            variant="outlined"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 30 }}
            placeholder={translations.t('forms.insertNation')}
            showErrorLabel
            required
          />
          <Field
            name="employment"
            component={MDRadioButtonsGroupField}
            containerstyle={{ marginBottom: 30 }}
            showErrorLabel
            radioLabelStyle={{ fontSize: 16 }}
            title={translations.t('forms.whatIsYourOccupancy')}
            required
            horizontal={!isMobile}
            options={[
              {
                id: 'STUDENT',
                value: translations.t('forms.student')
              },
              {
                id: 'UNEMPLOYED',
                value: translations.t('forms.unemployed')
              },
              {
                id: 'EMPLOYED',
                value: translations.t('forms.professionist')
              }
            ]}
          />
          <h4 className={classes.fieldTitle}>{translations.t('forms.whatIsYourJob')}</h4>
          <Field
            name="professionName"
            variant="outlined"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 25 }}
            placeholder={translations.t('forms.insertJobTitle')}
            showErrorLabel
          />
          <Field
            name="experienceAgeRange"
            component={MDRadioButtonsGroupField}
            containerstyle={{ marginBottom: 30 }}
            showErrorLabel
            radioLabelStyle={{ fontSize: 16 }}
            title={translations.t('forms.yearsOfExperince')}
            horizontal={!isMobile}
            options={[
              {
                id: 'RANGE0_1',
                value: translations.t('forms.lessThanOneYear')
              },
              {
                id: 'RANGE1_2',
                value: `1-2 ${translations.t('forms.years').toLocaleLowerCase()}`
              },
              {
                id: 'RANGE2_5',
                value: `2-5 ${translations.t('forms.years').toLocaleLowerCase()}`
              },
              {
                id: 'RANGE5_10',
                value: `5-10 ${translations.t('forms.years').toLocaleLowerCase()}`
              },
              {
                id: 'RANGE10_20',
                value: `10-20 ${translations.t('forms.years').toLocaleLowerCase()}`
              },
              {
                id: 'RANGE20_99',
                value: translations.t('forms.moreThanTwentiYear')
              }
            ]}
          />
          <Field
            name="educational"
            component={MDRadioButtonsGroupField}
            containerstyle={{ marginBottom: 30 }}
            showErrorLabel
            radioLabelStyle={{ fontSize: 16 }}
            title={translations.t('forms.whatIsYourScholarLevel')}
            required
            horizontal={!isMobile}
            options={[
              {
                id: 'SECONDARY_SCHOOL',
                value: translations.t('forms.highSchool')
              },
              {
                id: 'GRADUATION',
                value: translations.t('forms.universityDegree')
              },
              {
                id: 'POSTGRADUATE_CERTIFICATE',
                value: translations.t('forms.postUniversityDegree')
              }
            ]}
          />
          <Field
            name="badgesName"
            component={MDCheckBoxGroupField}
            containerstyle={{ marginBottom: 10 }}
            showErrorLabel
            horizontal={!isMobile}
            title={translations.t('forms.stageOrInsterests')}
            required
            options={[
              {
                id: 'COLLABORATION',
                label: translations.t('skills.COLLABORATION')
              },
              {
                id: 'CRITICAL_THINKING',
                label: translations.t('skills.CRITICAL_THINKING')
              },
              {
                id: 'PROBLEM_SOLVING',
                label: translations.t('skills.PROBLEM_SOLVING')
              },
              {
                id: 'LEADERSHIP',
                label: translations.t('skills.LEADERSHIP')
              },
              {
                id: 'COMMUNICATION',
                label: translations.t('skills.COMMUNICATION')
              },
              {
                id: 'INTERCULTURALISM',
                label: translations.t('skills.INTERCULTURALISM')
              },
              {
                id: 'LEARNING',
                label: translations.t('skills.LEARNING')
              }
            ]}
          />
          <Field
            name="description"
            variant="outlined"
            component={MDTextInputField}
            containerstyle={{ marginBottom: 25 }}
            placeholder={translations.t('forms.maxCharacters', { number: '255' })}
            onFormatText={(text) => _.truncate(text, { length: 255, omission: '.' })}
            showErrorLabel
            multiline
            rows="3"
          />
          <h4 className={classes.fieldTitle}>{translations.t('forms.stageOrInsterestsSupport')}</h4>
          <Field
            name="portfolioDescription"
            variant="outlined"
            multiline
            rows="3"
            component={MDTextInputField}
            placeholder={translations.t('forms.maxCharacters', { number: '255' })}
            onFormatText={(text) => _.truncate(text, { length: 255, omission: '.' })}
            containerstyle={{ marginBottom: 25 }}
            showErrorLabel
          />
          <Field
            name="badgeLinks"
            component={MDCheckBoxGroupField}
            containerstyle={{ marginBottom: 10 }}
            showErrorLabel
            horizontal={!isMobile}
            title={translations.t('forms.stageOrInsterestsBadges')}
            required
            options={[
              {
                id: 'COLLABORATION',
                label: translations.t('skills.COLLABORATION')
              },
              {
                id: 'CRITICAL_THINKING',
                label: translations.t('skills.CRITICAL_THINKING')
              },
              {
                id: 'PROBLEM_SOLVING',
                label: translations.t('skills.PROBLEM_SOLVING')
              },
              {
                id: 'LEADERSHIP',
                label: translations.t('skills.LEADERSHIP')
              },
              {
                id: 'COMMUNICATION',
                label: translations.t('skills.COMMUNICATION')
              },
              {
                id: 'INTERCULTURALISM',
                label: translations.t('skills.INTERCULTURALISM')
              },
              {
                id: 'LEARNING',
                label: translations.t('skills.LEARNING')
              }
            ]}
          />
          {currentForm &&
            currentForm.values &&
            currentForm.values.badgeLinks &&
            _.map(currentForm.values.badgeLinks, (skill) => (
              <Field
                name={`badgeLinks-${skill}`}
                label={translations.t(`skills.${skill}`)}
                component={MDTextInputField}
                containerstyle={{ marginBottom: 25 }}
                showErrorLabel
              />
            ))}
        </div>
      </MuiThemeProvider>
    );
  }
}

export default _.flow([
  reduxForm({
    form: 'OnboardingForm',
    validate
  }),
  withStyles(styles)
])(OnboardingForm);
